import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { FreeTierModal } from './FreeTierModal';
import { CryptoPriceSelector } from './CryptoPriceSelector';

const plans = [
  {
    name: "Gratuit",
    price: "0",
    features: [
      "Accès aux cours de base",
      "Éditeur de code en ligne",
      "Forum communautaire",
      "Projets simples"
    ]
  },
  {
    name: "Pro",
    price: "29",
    features: [
      "Tous les cours avancés",
      "Projets professionnels",
      "Support prioritaire",
      "Certifications",
      "Sessions de mentorat"
    ],
    popular: true
  },
  {
    name: "Entreprise",
    price: "Sur mesure",
    features: [
      "Formation personnalisée",
      "Support dédié",
      "API privée",
      "Rapports d'analyse",
      "Formations sur site"
    ]
  }
];

export function PricingPlans() {
  const [showFreeTier, setShowFreeTier] = useState(false);

  return (
    <section className="py-20 bg-black/50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Choisissez votre Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {plans.map((plan, index) => (
            <div 
              key={index}
              className={`bg-white/5 rounded-xl p-8 relative ${
                plan.popular ? 'ring-2 ring-blue-500 transform scale-105' : ''
              }`}
            >
              {plan.popular && (
                <span className="bg-blue-600 text-sm px-3 py-1 rounded-full absolute -top-3 left-1/2 transform -translate-x-1/2">
                  Populaire
                </span>
              )}
              <div className="text-center mb-8">
                <h3 className="text-xl font-semibold mb-4">{plan.name}</h3>
                {typeof plan.price === 'string' && !isNaN(Number(plan.price)) ? (
                  <CryptoPriceSelector price={plan.price} />
                ) : (
                  <p className="text-4xl font-bold">{plan.price}</p>
                )}
              </div>
              <ul className="space-y-4">
                {plan.features.map((feature, i) => (
                  <li key={i} className="flex items-center gap-3">
                    <Check className="w-5 h-5 text-blue-400" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <button 
                className="w-full mt-8 bg-blue-600 hover:bg-blue-700 py-3 rounded-lg font-medium"
                onClick={() => {
                  if (index === 0) setShowFreeTier(true);
                }}
              >
                Commencer
              </button>
            </div>
          ))}
        </div>
      </div>

      <FreeTierModal 
        isOpen={showFreeTier}
        onClose={() => setShowFreeTier(false)}
      />
    </section>
  );
}