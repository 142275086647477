import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { CryptoIcons } from './CryptoIcons';

interface CryptoPrice {
  symbol: string;
  name: string;
  color: string;
}

const cryptos: CryptoPrice[] = [
  { symbol: 'USDT', name: 'Tether', color: 'text-[#50AF95]' },
  { symbol: 'BTC', name: 'Bitcoin', color: 'text-[#F7931A]' },
  { symbol: 'ETH', name: 'Ethereum', color: 'text-[#627EEA]' },
  { symbol: 'SOL', name: 'Solana', color: 'text-[#9945FF]' },
  { symbol: 'XRP', name: 'XRP', color: 'text-[#23292F]' }
];

interface CryptoPriceSelectorProps {
  price: string;
}

export function CryptoPriceSelector({ price }: CryptoPriceSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState(cryptos[0]);

  const Icon = CryptoIcons[selectedCrypto.symbol as keyof typeof CryptoIcons];

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 hover:opacity-80 transition-all group"
      >
        <span className="text-4xl font-bold">{price}</span>
        <div className="flex items-center gap-1">
          <Icon />
          <ChevronDown className={`w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
        </div>
      </button>

      {isOpen && (
        <div className="absolute top-full left-0 mt-2 w-48 bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-xl border border-gray-700 py-2 z-50">
          {cryptos.map((crypto) => {
            const CryptoIcon = CryptoIcons[crypto.symbol as keyof typeof CryptoIcons];
            return (
              <button
                key={crypto.symbol}
                onClick={() => {
                  setSelectedCrypto(crypto);
                  setIsOpen(false);
                }}
                className="w-full px-4 py-2 text-left hover:bg-gray-700/50 flex items-center gap-2 group"
              >
                <CryptoIcon />
                <span className="font-medium">{crypto.name}</span>
                <span className={`ml-auto text-sm ${crypto.color}`}>{crypto.symbol}</span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}