import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, ChevronDown } from 'lucide-react';
import WhyParticipate from '../components/WhyParticipate';
import Schedule from '../components/Schedule';

export default function BlogPage() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  return (
    <div className="min-h-screen bg-[#111827]">
      {/* Header with Home CTA */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-md">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center gap-4">
              <h1 className="text-2xl font-bold text-white">Blog</h1>

              {/* Dropdown Menu */}
              <div className="relative ml-8">
                <button
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
                >
                  Menu
                  <ChevronDown className={`w-4 h-4 transition-transform ${showDropdown ? 'rotate-180' : ''}`} />
                </button>

                {showDropdown && (
                  <div className="absolute top-full left-0 mt-2 w-48 bg-black/90 backdrop-blur-sm rounded-lg shadow-xl border border-white/10 py-2">
                    <Link
                      to="/infopreneur/actualites"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/actualites'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Actualités
                    </Link>
                    <Link
                      to="/infopreneur/saas/tracker-btc"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/saas/tracker-btc'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Bitcoin Tracker
                    </Link>
                    <Link
                      to="/infopreneur/saas/learn-code"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/saas/learn-code'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Apprend a Code
                    </Link>
                    <Link
                      to="/infopreneur/saas/portfolio"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/saas/portfolio'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Portfolio d'Investissement
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <Link 
              to="/infopreneur"
              className="flex items-center gap-2 px-6 py-2 bg-[#fede58] text-black rounded-full font-medium hover:bg-[#fede58]/90 transition-colors group"
            >
              <Home className="w-5 h-5 transition-transform group-hover:rotate-12" />
              Home
            </Link>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="pt-24">
        <WhyParticipate />
        <Schedule />
      </div>
    </div>
  );
}