import axios from 'axios';

const COINGECKO_API = 'https://api.coingecko.com/api/v3';

export interface BitcoinData {
  current_price: number;
  price_change_percentage_24h: number;
  ath: number;
  ath_date: string;
  circulating_supply: number;
  total_supply: number;
  market_cap: number;
}

export const getBitcoinData = async (): Promise<BitcoinData> => {
  try {
    const response = await axios.get(
      `${COINGECKO_API}/coins/bitcoin?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`
    );

    const marketData = response.data.market_data;
    
    return {
      current_price: marketData.current_price.usd,
      price_change_percentage_24h: marketData.price_change_percentage_24h,
      ath: marketData.ath.usd,
      ath_date: marketData.ath_date.usd,
      circulating_supply: marketData.circulating_supply,
      total_supply: marketData.total_supply,
      market_cap: marketData.market_cap.usd
    };
  } catch (error) {
    throw new Error('Erreur lors de la récupération des données Bitcoin');
  }
};