import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, BookOpen, Library, Users, ChevronDown, Code2, Terminal, Braces } from 'lucide-react';

export const CodeHeader = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-md">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center gap-4">
            <Link 
              to="/infopreneur/saas/learn-code"
              className="flex items-center gap-2 group"
            >
              {/* Animated Developer Logo */}
              <div className="relative w-10 h-10">
                {/* Background glow effect */}
                <div className="absolute inset-0 bg-blue-500/20 rounded-lg blur-lg animate-pulse"></div>
                
                {/* Main logo container */}
                <div className="relative bg-gradient-to-br from-blue-600 to-purple-600 rounded-lg p-2 transform transition-all duration-300 group-hover:scale-110 group-hover:rotate-3">
                  {/* Animated code brackets */}
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <Braces className="w-6 h-6 text-white animate-pulse" />
                  </div>
                  
                  {/* Terminal icon */}
                  <Terminal className="w-6 h-6 text-white transform transition-transform duration-300 group-hover:scale-0" />
                </div>

                {/* Floating particles */}
                <div className="absolute -top-1 -right-1 w-2 h-2 bg-blue-400 rounded-full animate-ping"></div>
                <div className="absolute -bottom-1 -left-1 w-2 h-2 bg-purple-400 rounded-full animate-ping" style={{ animationDelay: '0.5s' }}></div>
              </div>
              
              <span className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                Learn_Code
              </span>
            </Link>

            {/* Rest of the header content */}
            {/* Dropdown Menu */}
            <div className="relative">
              <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
              >
                Menu
                <ChevronDown className={`w-4 h-4 transition-transform ${showDropdown ? 'rotate-180' : ''}`} />
              </button>

              {showDropdown && (
                <div className="absolute top-full left-0 mt-2 w-48 bg-black/90 backdrop-blur-sm rounded-lg shadow-xl border border-white/10 py-2">
                  <Link 
                    to="/infopreneur/actualites"
                    className={`block px-4 py-2 text-sm ${
                      location.pathname === '/infopreneur/actualites'
                        ? 'bg-white/10 text-white'
                        : 'text-gray-300 hover:bg-white/5 hover:text-white'
                    } transition-colors`}
                    onClick={() => setShowDropdown(false)}
                  >
                    Actualités
                  </Link>
                  <Link 
                    to="/infopreneur/blog"
                    className={`block px-4 py-2 text-sm ${
                      location.pathname === '/infopreneur/blog'
                        ? 'bg-white/10 text-white'
                        : 'text-gray-300 hover:bg-white/5 hover:text-white'
                    } transition-colors`}
                    onClick={() => setShowDropdown(false)}
                  >
                    Blog
                  </Link>
                  <Link 
                    to="/infopreneur/saas/tracker-btc"
                    className={`block px-4 py-2 text-sm ${
                      location.pathname === '/infopreneur/saas/tracker-btc'
                        ? 'bg-white/10 text-white'
                        : 'text-gray-300 hover:bg-white/5 hover:text-white'
                    } transition-colors`}
                    onClick={() => setShowDropdown(false)}
                  >
                    Bitcoin Tracker
                  </Link>
                  <Link 
                    to="/infopreneur/saas/portfolio"
                    className={`block px-4 py-2 text-sm ${
                      location.pathname === '/infopreneur/saas/portfolio'
                        ? 'bg-white/10 text-white'
                        : 'text-gray-300 hover:bg-white/5 hover:text-white'
                    } transition-colors`}
                    onClick={() => setShowDropdown(false)}
                  >
                    Portfolio d'Investissement
                  </Link>
                </div>
              )}
            </div>
            
            <nav className="hidden md:flex items-center gap-6">
              <Link 
                to="/infopreneur/blog"
                className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors group"
              >
                <BookOpen className="w-5 h-5 transition-transform group-hover:scale-110" />
                Blog
              </Link>
              <Link 
                to="/infopreneur/saas/learn-code#resources"
                className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors group"
              >
                <Library className="w-5 h-5 transition-transform group-hover:scale-110" />
                Resources
              </Link>
              <Link 
                to="/infopreneur/saas/learn-code#community"
                className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors group"
              >
                <Users className="w-5 h-5 transition-transform group-hover:scale-110" />
                Community
              </Link>
            </nav>
          </div>
          
          <Link 
            to="/infopreneur"
            className="flex items-center gap-2 px-6 py-2 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors"
          >
            <Home className="w-5 h-5" />
            Home
          </Link>
        </div>
      </div>
    </header>
  );
};