import React from 'react';
import { X } from 'lucide-react';

interface PracticalLearningModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function PracticalLearningModal({ isOpen, onClose }: PracticalLearningModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-gray-900/95 backdrop-blur-md w-full max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 text-white"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Éditeurs de Code Interactifs</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="space-y-6">
          <p className="text-gray-300">
            Si vous souhaitez pratiquer et coder directement dans votre navigateur, plusieurs plateformes 
            offrent des éditeurs interactifs pour différents langages et technologies. Voici quelques 
            suggestions populaires selon vos besoins :
          </p>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">1. Plateformes d'apprentissage interactif</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">a. Replit</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Multilingue : prend en charge Python, JavaScript, C++, Java, HTML/CSS, etc.</li>
                  <li>Collaboration : travail en équipe possible.</li>
                  <li>Idéal pour les débutants et les projets rapides.</li>
                </ul>
              </div>
              
              <div>
                <h4 className="font-semibold text-white">b. CodeSandbox</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Parfait pour le développement web : React, Vue, Angular, HTML/CSS/JS.</li>
                  <li>Interface fluide avec des outils de prévisualisation.</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">c. LeetCode</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Conçu pour résoudre des problèmes d'algorithmes et de structures de données.</li>
                  <li>Multilingue : Python, JavaScript, Java, etc.</li>
                  <li>Idéal pour préparer des entretiens techniques.</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">2. Éditeurs en ligne pour des langages spécifiques</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">a. Python</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>PyScript: Une manière innovante d'exécuter Python dans un navigateur web.</li>
                  <li>Google Colab: Exécutez des notebooks Python gratuitement sur le cloud.</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">b. Web (HTML/CSS/JS)</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>CodePen: Excellent pour prototyper rapidement du code front-end.</li>
                  <li>JSFiddle: Plateforme simple pour tester HTML, CSS, et JavaScript.</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">3. Ateliers interactifs avec tutoriels intégrés</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">freeCodeCamp :</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Apprenez en résolvant des exercices pratiques.</li>
                  <li>Axé sur le développement web, Python, et la science des données.</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">4. Systèmes embarqués ou Linux</h3>
            <div className="pl-4 text-gray-300">
              <p>Si vous apprenez OpenBSD ou d'autres systèmes UNIX :</p>
              <ul className="list-disc pl-6 space-y-1 mt-2">
                <li>W3Schools Tryit Editor : pratiquez des commandes shell dans un environnement limité.</li>
                <li>Webminal et JSLinux pour un terminal Linux en ligne.</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">5. Environnements auto-hébergés</h3>
            <div className="pl-4 text-gray-300">
              <p>Si vous voulez une solution plus privée mais accessible depuis votre navigateur :</p>
              <ul className="list-disc pl-6 space-y-1 mt-2">
                <li>Coder : déployez Visual Studio Code sur un serveur distant.</li>
                <li>Theia : alternative open-source à VS Code pour des environnements cloud.</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}