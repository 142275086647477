import React, { useState, useEffect } from 'react';
import { ArrowRightLeft } from 'lucide-react';
import { getBitcoinData } from '../../services/bitcoin';

export default function Converter() {
  const [amount, setAmount] = useState(1);
  const [fromCurrency, setFromCurrency] = useState('BTC');
  const [toCurrency, setToCurrency] = useState('USD');
  const [btcPrice, setBtcPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBitcoinPrice = async () => {
      try {
        setLoading(true);
        const data = await getBitcoinData();
        setBtcPrice(data.current_price);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Erreur lors de la récupération du prix');
      } finally {
        setLoading(false);
      }
    };

    fetchBitcoinPrice();
    const interval = setInterval(fetchBitcoinPrice, 30000);
    return () => clearInterval(interval);
  }, []);

  const convertedAmount = fromCurrency === 'BTC' 
    ? btcPrice ? amount * btcPrice : 0
    : btcPrice ? amount / btcPrice : 0;

  return (
    <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-2">
              Montant
            </label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              className="w-full px-4 py-2 bg-white/10 rounded-lg focus:ring-2 focus:ring-[#F7931A] border-none"
              min="0"
              step="0.00000001"
            />
          </div>

          <div className="flex gap-4 items-center">
            <div className="flex-1">
              <label className="block text-sm font-medium mb-2">
                De
              </label>
              <select
                value={fromCurrency}
                onChange={(e) => {
                  setFromCurrency(e.target.value);
                  setToCurrency(e.target.value === 'BTC' ? 'USD' : 'BTC');
                }}
                className="w-full px-4 py-2 bg-white/10 rounded-lg focus:ring-2 focus:ring-[#F7931A] border-none"
              >
                <option value="BTC">BTC</option>
                <option value="USD">USD</option>
              </select>
            </div>

            <button 
              className="mt-6 p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
              onClick={() => {
                setFromCurrency(toCurrency);
                setToCurrency(fromCurrency);
              }}
            >
              <ArrowRightLeft className="w-5 h-5" />
            </button>

            <div className="flex-1">
              <label className="block text-sm font-medium mb-2">
                Vers
              </label>
              <select
                value={toCurrency}
                onChange={(e) => {
                  setToCurrency(e.target.value);
                  setFromCurrency(e.target.value === 'BTC' ? 'USD' : 'BTC');
                }}
                className="w-full px-4 py-2 bg-white/10 rounded-lg focus:ring-2 focus:ring-[#F7931A] border-none"
              >
                <option value="USD">USD</option>
                <option value="BTC">BTC</option>
              </select>
            </div>
          </div>
        </div>

        <div className="bg-white/5 rounded-xl p-6">
          <h3 className="text-xl font-semibold mb-4">Résultat</h3>
          {loading ? (
            <div className="flex items-center justify-center py-8">
              <div className="w-8 h-8 border-4 border-[#F7931A] border-t-transparent rounded-full animate-spin"></div>
            </div>
          ) : error ? (
            <div className="text-red-400 text-center p-4">
              {error}
            </div>
          ) : (
            <>
              <p className="text-3xl font-bold">
                {amount.toLocaleString()} {fromCurrency} =
              </p>
              <p className="text-4xl font-bold text-[#F7931A]">
                {convertedAmount.toLocaleString(undefined, { 
                  maximumFractionDigits: fromCurrency === 'BTC' ? 2 : 8 
                })} {toCurrency}
              </p>
              <p className="text-sm text-gray-400 mt-2">
                1 BTC = ${btcPrice?.toLocaleString()}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}