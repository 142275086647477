import React, { useState } from 'react';
import { Bitcoin, Twitter, Github } from 'lucide-react';
import HalvingModal from './HalvingModal';
import SecurityModal from './SecurityModal';
import DCAModal from './DCAModal';
import ConverterModal from './ConverterModal';
import ApiModal from './ApiModal';

export default function BitcoinFooter() {
  const [showHalvingModal, setShowHalvingModal] = useState(false);
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const [showDCAModal, setShowDCAModal] = useState(false);
  const [showConverterModal, setShowConverterModal] = useState(false);
  const [showApiModal, setShowApiModal] = useState(false);

  return (
    <footer className="bg-black/50 backdrop-blur-md border-t border-white/10 py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center gap-2 mb-4">
              <Bitcoin className="w-8 h-8 text-[#F7931A]" />
              <span className="text-xl font-bold">Bitcoin Tracker</span>
            </div>
            <p className="text-gray-400">
              Suivez le prix du Bitcoin et analysez son évolution en temps réel.
            </p>
          </div>

          <div>
            <h3 className="font-semibold mb-4">Ressources</h3>
            <ul className="space-y-2 text-gray-400">
              <li>
                <a href="#" className="hover:text-white transition-colors">
                  Guide du Bitcoin
                </a>
              </li>
              <li>
                <button 
                  onClick={() => setShowHalvingModal(true)}
                  className="hover:text-white transition-colors"
                >
                  Halving
                </button>
              </li>
              <li>
                <button
                  onClick={() => setShowSecurityModal(true)}
                  className="hover:text-white transition-colors"
                >
                  Sécurité
                </button>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold mb-4">Outils</h3>
            <ul className="space-y-2 text-gray-400">
              <li>
                <button
                  onClick={() => setShowDCAModal(true)}
                  className="hover:text-white transition-colors"
                >
                  Simulateur DCA
                </button>
              </li>
              <li>
                <button
                  onClick={() => setShowConverterModal(true)}
                  className="hover:text-white transition-colors"
                >
                  Convertisseur
                </button>
              </li>
              <li>
                <button
                  onClick={() => setShowApiModal(true)}
                  className="hover:text-white transition-colors"
                >
                  API
                </button>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold mb-4">Suivez-nous</h3>
            <div className="flex gap-4">
              <a 
                href="#" 
                className="text-gray-400 hover:text-white transition-colors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter className="w-6 h-6" />
              </a>
              <a 
                href="#" 
                className="text-gray-400 hover:text-white transition-colors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Github className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-white/10 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Bitcoin Tracker. Tous droits réservés.</p>
        </div>
      </div>

      <HalvingModal 
        isOpen={showHalvingModal}
        onClose={() => setShowHalvingModal(false)}
      />
      <SecurityModal
        isOpen={showSecurityModal}
        onClose={() => setShowSecurityModal(false)}
      />
      <DCAModal
        isOpen={showDCAModal}
        onClose={() => setShowDCAModal(false)}
      />
      <ConverterModal
        isOpen={showConverterModal}
        onClose={() => setShowConverterModal(false)}
      />
      <ApiModal
        isOpen={showApiModal}
        onClose={() => setShowApiModal(false)}
      />
    </footer>
  );
}