import React, { useState, useEffect } from 'react';
import { TrendingUp, TrendingDown, Clock } from 'lucide-react';
import { getBitcoinData } from '../../services/bitcoin';

export default function BitcoinStats() {
  const [bitcoinData, setBitcoinData] = useState({
    price: 0,
    change24h: 0,
    ath: 0,
    athDate: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [remainingDays, setRemainingDays] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBitcoinData();
        setBitcoinData({
          price: data.current_price,
          change24h: data.price_change_percentage_24h,
          ath: data.ath,
          athDate: new Date(data.ath_date).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })
        });
        setError('');
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Une erreur est survenue');
      } finally {
        setLoading(false);
      }
    };

    // Calculer les jours jusqu'au prochain halving
    const nextHalving = new Date('2028-04-15');
    const today = new Date();
    const diffTime = Math.abs(nextHalving.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setRemainingDays(diffDays);

    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
        {[1, 2, 3].map((i) => (
          <div key={i} className="bg-white/5 rounded-xl p-6 backdrop-blur-sm animate-pulse">
            <div className="h-8 bg-white/10 rounded mb-4"></div>
            <div className="h-10 bg-white/10 rounded mb-2"></div>
            <div className="h-6 bg-white/10 rounded w-1/2"></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
      <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">Prix actuel</h3>
          {bitcoinData.change24h >= 0 ? (
            <TrendingUp className="w-6 h-6 text-green-400" />
          ) : (
            <TrendingDown className="w-6 h-6 text-red-400" />
          )}
        </div>
        <p className="text-3xl font-bold">
          ${bitcoinData.price.toLocaleString()}
        </p>
        <p className={`text-sm ${bitcoinData.change24h >= 0 ? 'text-green-400' : 'text-red-400'}`}>
          {bitcoinData.change24h >= 0 ? '+' : ''}{bitcoinData.change24h.toFixed(2)}% (24h)
        </p>
      </div>

      <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">ATH</h3>
          <TrendingUp className="w-6 h-6 text-green-400" />
        </div>
        <p className="text-3xl font-bold">
          ${bitcoinData.ath.toLocaleString()}
        </p>
        <p className="text-sm text-gray-400">{bitcoinData.athDate}</p>
      </div>

      <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">Prochain Halving</h3>
          <Clock className="w-6 h-6 text-blue-400" />
        </div>
        <p className="text-3xl font-bold">{remainingDays} jours</p>
        <p className="text-sm text-gray-400">15 Avril 2028</p>
      </div>
    </div>
  );
}