import React from 'react';
import BitcoinHeader from '../components/bitcoin-tracker/BitcoinHeader';
import BitcoinFooter from '../components/bitcoin-tracker/BitcoinFooter';
import PriceEvolution from '../components/bitcoin-tracker/PriceEvolution';
import BitcoinSupply from '../components/bitcoin-tracker/BitcoinSupply';
import DCASimulator from '../components/bitcoin-tracker/DCASimulator';
import Converter from '../components/bitcoin-tracker/Converter';
import BitcoinStats from '../components/bitcoin-tracker/BitcoinStats';

export default function BitcoinTrackerPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
      <BitcoinHeader />
      
      <main className="container mx-auto px-4 py-20">
        {/* Hero Section */}
        <div className="text-center mb-16 pt-8">
          <h1 className="text-5xl font-bold mb-4">Bitcoin Tracker</h1>
          <p className="text-xl text-gray-400">
            Suivez le prix du Bitcoin et analysez son évolution
          </p>
        </div>

        {/* Bitcoin Stats */}
        <BitcoinStats />

        {/* Price Evolution */}
        <section className="mb-16" id="price">
          <h2 className="text-3xl font-bold mb-8">Évolution du Prix</h2>
          <PriceEvolution />
        </section>

        {/* Bitcoin Supply */}
        <section className="mb-16" id="supply">
          <h2 className="text-3xl font-bold mb-8">Offre Bitcoin</h2>
          <BitcoinSupply />
        </section>

        {/* DCA Simulator */}
        <section className="mb-16" id="simulator">
          <h2 className="text-3xl font-bold mb-8">Simulateur DCA</h2>
          <DCASimulator />
        </section>

        {/* Converter */}
        <section className="mb-16" id="converter">
          <h2 className="text-3xl font-bold mb-8">Convertisseur</h2>
          <Converter />
        </section>
      </main>

      <BitcoinFooter />
    </div>
  );
}