import React from 'react';
import { CodeHeader } from '../components/learn-code/CodeHeader';
import { CodeHero } from '../components/learn-code/CodeHero';
import { CoursesList } from '../components/learn-code/CoursesList';
import { Features } from '../components/learn-code/Features';
import { Testimonials } from '../components/learn-code/Testimonials';
import { PricingPlans } from '../components/learn-code/PricingPlans';
import { CodeFooter } from '../components/learn-code/CodeFooter';

const LearnCodePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white pt-16">
      <CodeHeader />
      <CodeHero />
      <Features />
      <CoursesList />
      <Testimonials />
      <PricingPlans />
      <CodeFooter />
    </div>
  );
};

export default LearnCodePage;