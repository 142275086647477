import React from 'react';

const courses = [
  {
    title: "JavaScript Fondamentaux",
    level: "Débutant",
    duration: "8 semaines",
    image: "https://images.unsplash.com/photo-1579468118864-1b9ea3c0db4a?auto=format&fit=crop&q=80&w=400",
    tags: ["JavaScript", "Front-end", "Web"]
  },
  {
    title: "React Avancé",
    level: "Intermédiaire",
    duration: "10 semaines",
    image: "https://images.unsplash.com/photo-1633356122544-f134324a6cee?auto=format&fit=crop&q=80&w=400",
    tags: ["React", "TypeScript", "Redux"]
  },
  {
    title: "Node.js & Express",
    level: "Intermédiaire",
    duration: "8 semaines",
    image: "https://images.unsplash.com/photo-1627398242454-45a1465c2479?auto=format&fit=crop&q=80&w=400",
    tags: ["Node.js", "Back-end", "API"]
  }
];

export function CoursesList() {
  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Nos Cours Populaires</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {courses.map((course, index) => (
            <div key={index} className="bg-white/5 rounded-xl overflow-hidden hover:transform hover:scale-105 transition-all">
              <img src={course.image} alt={course.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <h3 className="text-xl font-semibold">{course.title}</h3>
                  <span className="bg-blue-600/20 text-blue-400 px-3 py-1 rounded-full text-sm">
                    {course.level}
                  </span>
                </div>
                <p className="text-gray-400 mb-4">{course.duration}</p>
                <div className="flex flex-wrap gap-2">
                  {course.tags.map((tag, i) => (
                    <span key={i} className="bg-white/10 px-3 py-1 rounded-full text-sm">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}