import React from 'react';
import { X } from 'lucide-react';

interface ThemesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const themes = [
  {
    title: "1. Intelligence Artificielle et Automatisation",
    idea: "Exploiter l'IA pour optimiser les entreprises et les tâches personnelles.",
    examples: [
      "Formation : \"Comment utiliser ChatGPT et d'autres outils d'IA pour booster votre business en ligne\"",
      "Coaching : \"Automatisation de vos tâches administratives avec Zapier et Make (ex-Integromat)\"",
      "E-book : \"50 stratégies pour intégrer l'IA dans votre quotidien professionnel\""
    ]
  },
  {
    title: "2. Nomadisme Digital et Vie Délocalisée",
    idea: "Aider les individus à travailler en ligne tout en voyageant ou en vivant à l'étranger.",
    examples: [
      "Formation : \"Devenir un nomade digital : les étapes pour travailler depuis n'importe où\"",
      "Ressources : \"Guide des visas nomades pour 2024\"",
      "Webinaire : \"Construire une routine de travail efficace en tant que nomade\""
    ]
  },
  {
    title: "3. Monétisation des Réseaux Sociaux",
    idea: "Capitaliser sur TikTok, Instagram, et YouTube pour générer des revenus.",
    examples: [
      "Formation : \"Créer du contenu viral sur TikTok et en tirer des revenus passifs\"",
      "Atelier : \"Monétisation sur YouTube avec un contenu minimaliste\"",
      "Coaching : \"Stratégie de marque personnelle sur Instagram\""
    ]
  },
  {
    title: "4. Santé Mentale et Productivité",
    idea: "Répondre au besoin croissant de gestion du stress et de la performance mentale.",
    examples: [
      "Programme : \"Mindfulness pour entrepreneurs : réduire le stress et améliorer la créativité\"",
      "E-book : \"Techniques pratiques pour équilibrer travail et bien-être mental\"",
      "Webinaire : \"Les neurosciences appliquées à la productivité\""
    ]
  },
  {
    title: "5. Création et Vente de Produits Numériques",
    idea: "Apprendre aux entrepreneurs à monétiser leur expertise via des produits numériques.",
    examples: [
      "Formation : \"De zéro à expert : créer et vendre votre premier e-book\"",
      "Coaching : \"Structurer et lancer votre formation en ligne en 30 jours\"",
      "Guide : \"Comment générer des revenus passifs avec des cours préenregistrés\""
    ]
  },
  {
    title: "6. Marketing d'Influence pour Solopreneurs",
    idea: "Aider les petits entrepreneurs à collaborer avec des influenceurs ou à devenir eux-mêmes influenceurs.",
    examples: [
      "Formation : \"Trouver et collaborer avec des micro-influenceurs pour votre marque\"",
      "Coaching : \"Devenir un influenceur de niche en 2024\"",
      "Atelier : \"Créer des partenariats gagnants grâce au marketing d'affiliation\""
    ]
  },
  {
    title: "7. Réinvention Professionnelle et Requalification",
    idea: "Accompagner les transitions de carrière dans un monde en pleine évolution.",
    examples: [
      "Programme : \"Réinventez votre carrière grâce aux compétences numériques\"",
      "Webinaire : \"Passer de salarié à entrepreneur en 6 mois\"",
      "Ressources : \"Les 10 compétences les plus demandées en 2024\""
    ]
  },
  {
    title: "8. Économie Circulaire et Business Éco-Responsable",
    idea: "Sensibiliser et guider les entrepreneurs vers des modèles d'affaires durables.",
    examples: [
      "Atelier : \"Créer un business éthique et éco-responsable\"",
      "E-book : \"Guide des outils pour lancer une entreprise dans l'économie circulaire\"",
      "Webinaire : \"Monétiser votre expertise dans le domaine du développement durable\""
    ]
  },
  {
    title: "9. Technologies Web3 et Cryptoéconomie",
    idea: "Éduquer les débutants et experts sur les opportunités du Web3, des NFTs et des cryptomonnaies.",
    examples: [
      "Formation : \"Web3 pour les nuls : tout comprendre et s'initier à la blockchain\"",
      "Guide : \"Créer et vendre vos propres NFTs\"",
      "Coaching : \"Développer des stratégies de revenus passifs avec la cryptoéconomie\""
    ]
  },
  {
    title: "10. Parentalité et Éducation 3.0",
    idea: "Accompagner les parents dans l'éducation numérique et la parentalité moderne.",
    examples: [
      "Programme : \"Aider vos enfants à devenir des créateurs numériques responsables\"",
      "Ressources : \"10 outils pour équilibrer télétravail et parentalité\"",
      "Webinaire : \"L'école à la maison avec les outils numériques\""
    ]
  },
  {
    title: "11. Création d'Écosystèmes Abonnement et Membership",
    idea: "Enseigner à créer des revenus récurrents grâce aux abonnements.",
    examples: [
      "Formation : \"Construire un site de membership en 7 étapes\"",
      "Coaching : \"Créer une communauté payante autour de votre expertise\"",
      "Ressources : \"Les meilleurs outils pour gérer un abonnement en ligne\""
    ]
  },
  {
    title: "12. Gamification pour Entreprises",
    idea: "Aider les entreprises et solopreneurs à engager leur audience en rendant leurs services ludiques.",
    examples: [
      "Programme : \"Gamifier vos formations pour améliorer l'engagement\"",
      "Atelier : \"Comment utiliser la gamification pour fidéliser vos clients\"",
      "Guide : \"Créer des challenges interactifs pour votre communauté\""
    ]
  },
  {
    title: "13. Spiritualité et Développement Intuitif",
    idea: "Explorer les nouvelles approches du bien-être spirituel et émotionnel.",
    examples: [
      "Atelier : \"Aligner votre entreprise avec vos valeurs spirituelles\"",
      "Programme : \"Développer votre intuition pour une prise de décision éclairée\"",
      "E-book : \"Méditations guidées pour entrepreneurs stressés\""
    ]
  },
  {
    title: "14. Cybersécurité pour Solopreneurs",
    idea: "Former les entrepreneurs en ligne à sécuriser leurs données et leurs activités.",
    examples: [
      "Formation : \"Les bases de la cybersécurité pour les infopreneurs\"",
      "Guide : \"Protéger votre site contre les cyberattaques\"",
      "Atelier : \"Sécuriser vos transactions en ligne et données clients\""
    ]
  },
  {
    title: "15. Métavers et Réalités Virtuelles",
    idea: "Exploiter les opportunités offertes par les mondes virtuels pour les formations et les événements.",
    examples: [
      "Webinaire : \"Organiser des ateliers immersifs dans le métavers\"",
      "Programme : \"Créer un showroom virtuel pour votre expertise\"",
      "Guide : \"Utiliser la réalité virtuelle pour engager votre audience\""
    ]
  }
];

export default function ThemesModal({ isOpen, onClose }: ThemesModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white/90 backdrop-blur-md max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 shadow-xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">15 Thématiques innovantes pour un infopreneur en 2025</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-black/5 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="prose prose-lg max-w-none">
          {themes.map((theme, index) => (
            <div key={index} className="mb-8 pb-8 border-b last:border-0">
              <h3 className="text-xl font-bold mb-4">{theme.title}</h3>
              <p className="mb-4">{theme.idea}</p>
              <div className="pl-4">
                <p className="font-semibold mb-2">Exemples :</p>
                <ul className="list-disc pl-4">
                  {theme.examples.map((example, i) => (
                    <li key={i}>{example}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}