import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TrendingUp, Coins, Calculator, Home, X, Clock, Bitcoin, ChevronDown } from 'lucide-react';
import { getBitcoinData } from '../../services/bitcoin';
import HalvingModal from './HalvingModal';

export default function BitcoinHeader() {
  const [showPricePopup, setShowPricePopup] = useState(false);
  const [showHalvingModal, setShowHalvingModal] = useState(false);
  const [bitcoinPrice, setBitcoinPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (showPricePopup) {
      const fetchPrice = async () => {
        try {
          setLoading(true);
          const data = await getBitcoinData();
          setBitcoinPrice(data.current_price);
          setError(null);
        } catch (err) {
          setError('Erreur lors de la récupération du prix');
        } finally {
          setLoading(false);
        }
      };

      fetchPrice();
      const interval = setInterval(fetchPrice, 30000);
      return () => clearInterval(interval);
    }
  }, [showPricePopup]);

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-md">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center gap-4">
            <Bitcoin className="w-8 h-8 text-[#F7931A]" />
            <span className="text-xl font-bold">Bitcoin Tracker</span>

            {/* Dropdown Menu */}
            <div className="relative ml-8">
              <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
              >
                Menu
                <ChevronDown className={`w-4 h-4 transition-transform ${showDropdown ? 'rotate-180' : ''}`} />
              </button>

              {showDropdown && (
                <div className="absolute top-full left-0 mt-2 w-48 bg-black/90 backdrop-blur-sm rounded-lg shadow-xl border border-white/10 py-2">
                  <Link
                    to="/infopreneur/actualites"
                    className={`block px-4 py-2 text-sm ${
                      location.pathname === '/infopreneur/actualites'
                        ? 'bg-white/10 text-white'
                        : 'text-gray-300 hover:bg-white/5 hover:text-white'
                    } transition-colors`}
                    onClick={() => setShowDropdown(false)}
                  >
                    Actualités
                  </Link>
                  <Link
                    to="/infopreneur/blog"
                    className={`block px-4 py-2 text-sm ${
                      location.pathname === '/infopreneur/blog'
                        ? 'bg-white/10 text-white'
                        : 'text-gray-300 hover:bg-white/5 hover:text-white'
                    } transition-colors`}
                    onClick={() => setShowDropdown(false)}
                  >
                    Blog
                  </Link>
                  <Link
                    to="/infopreneur/saas/learn-code"
                    className={`block px-4 py-2 text-sm ${
                      location.pathname === '/infopreneur/saas/learn-code'
                        ? 'bg-white/10 text-white'
                        : 'text-gray-300 hover:bg-white/5 hover:text-white'
                    } transition-colors`}
                    onClick={() => setShowDropdown(false)}
                  >
                    Apprend a Code
                  </Link>
                  <Link
                    to="/infopreneur/saas/portfolio"
                    className={`block px-4 py-2 text-sm ${
                      location.pathname === '/infopreneur/saas/portfolio'
                        ? 'bg-white/10 text-white'
                        : 'text-gray-300 hover:bg-white/5 hover:text-white'
                    } transition-colors`}
                    onClick={() => setShowDropdown(false)}
                  >
                    Portfolio d'Investissement
                  </Link>
                </div>
              )}
            </div>
          </div>

          <nav className="hidden md:flex items-center gap-8">
            <button 
              onClick={() => setShowPricePopup(true)}
              className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
            >
              <TrendingUp className="w-5 h-5" />
              <span>Prix</span>
            </button>
            <button 
              onClick={() => scrollToSection('converter')}
              className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
            >
              <Coins className="w-5 h-5" />
              <span>Offre</span>
            </button>
            <button 
              onClick={() => scrollToSection('simulator')}
              className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
            >
              <Calculator className="w-5 h-5" />
              <span>Simulateur</span>
            </button>
            <button 
              onClick={() => setShowHalvingModal(true)}
              className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
            >
              <Clock className="w-5 h-5" />
              <span>Halving</span>
            </button>
          </nav>

          <Link 
            to="/infopreneur"
            className="flex items-center gap-2 px-6 py-2 bg-[#F7931A] text-black rounded-full font-medium hover:bg-[#F7931A]/90 transition-colors"
          >
            <Home className="w-5 h-5" />
            Home
          </Link>
        </div>
      </div>

      {/* Price Popup */}
      {showPricePopup && (
        <div className="fixed inset-0 z-50 flex items-start justify-center pt-20">
          <div 
            className="fixed inset-0 bg-black/50 backdrop-blur-sm"
            onClick={() => setShowPricePopup(false)}
          ></div>
          
          <div className="relative bg-gray-900/90 backdrop-blur-md rounded-xl p-6 shadow-2xl border border-white/10 w-full max-w-sm mx-4 transform transition-all duration-200 animate-[fadeIn_0.2s_ease-out]">
            <button 
              onClick={() => setShowPricePopup(false)}
              className="absolute top-4 right-4 p-1 hover:bg-white/10 rounded-full transition-colors"
            >
              <X className="w-5 h-5" />
            </button>

            <h3 className="text-xl font-semibold mb-4">Prix du Bitcoin</h3>
            
            {loading ? (
              <div className="flex items-center justify-center py-8">
                <div className="w-8 h-8 border-4 border-[#F7931A] border-t-transparent rounded-full animate-spin"></div>
              </div>
            ) : error ? (
              <div className="text-red-400 text-center py-4">{error}</div>
            ) : (
              <div className="space-y-4">
                <div className="bg-white/5 rounded-lg p-4">
                  <p className="text-sm text-gray-400 mb-1">Prix actuel</p>
                  <p className="text-3xl font-bold">${bitcoinPrice?.toLocaleString()}</p>
                </div>
                <p className="text-sm text-gray-400 text-center">
                  Prix mis à jour toutes les 30 secondes
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Halving Modal */}
      <HalvingModal 
        isOpen={showHalvingModal}
        onClose={() => setShowHalvingModal(false)}
      />
    </header>
  );
}