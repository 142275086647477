import React from 'react';
import { Link } from 'react-router-dom';
import { Newspaper, BookOpen, Box, Activity, Home, Code, Bitcoin, LineChart } from 'lucide-react';

interface InfoHeaderProps {
  onMonitoringClick?: () => void;
}

const InfoHeader: React.FC<InfoHeaderProps> = ({ onMonitoringClick }) => {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-md">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center space-x-12">
            <div className="group relative">
              <Link 
                to="/infopreneur/actualites"
                className="flex items-center space-x-2 text-white hover:text-[#fede58] transition-colors"
              >
                <Newspaper className="w-5 h-5 animate-bounce" />
                <span>Actualités</span>
              </Link>
              <div className="absolute top-full left-0 mt-2 py-2 bg-black/80 backdrop-blur-sm rounded-lg min-w-[160px] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all transform translate-y-2 group-hover:translate-y-0">
                <Link 
                  to="/infopreneur/edition"
                  className="block px-4 py-2 text-gray-400 hover:text-[#fede58] transition-colors whitespace-nowrap"
                >
                  Edition
                </Link>
                <Link 
                  to="/infopreneur/temoignage"
                  className="block px-4 py-2 text-gray-400 hover:text-[#fede58] transition-colors whitespace-nowrap"
                >
                  Témoignage
                </Link>
                <Link 
                  to="/infopreneur/faq"
                  className="block px-4 py-2 text-gray-400 hover:text-[#fede58] transition-colors whitespace-nowrap"
                >
                  FAQ
                </Link>
              </div>
            </div>
            
            <Link 
              to="/infopreneur/blog"
              className="flex items-center space-x-2 text-white hover:text-[#fede58] transition-colors"
            >
              <BookOpen className="w-5 h-5 animate-pulse" />
              <span>Blog</span>
            </Link>
            
            <div className="group relative">
              <Link 
                to="/infopreneur/saas"
                className="flex items-center space-x-2 text-white hover:text-[#fede58] transition-colors"
              >
                <Box className="w-5 h-5 animate-spin-slow" />
                <span>SaaS</span>
              </Link>
              <div className="absolute top-full left-0 mt-2 py-2 bg-black/80 backdrop-blur-sm rounded-lg min-w-[160px] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all transform translate-y-2 group-hover:translate-y-0">
                <Link 
                  to="/infopreneur/saas/learn-code"
                  className="flex items-center gap-2 px-4 py-2 text-gray-400 hover:text-[#fede58] transition-colors whitespace-nowrap"
                >
                  <Code className="w-5 h-5" />
                  <span>Apprend_a_Code</span>
                </Link>
                <Link 
                  to="/infopreneur/saas/tracker-btc"
                  className="flex items-center gap-2 px-4 py-2 text-gray-400 hover:text-[#fede58] transition-colors whitespace-nowrap"
                >
                  <Bitcoin className="w-5 h-5" />
                  <span>tracker_btc</span>
                </Link>
                <Link 
                  to="/infopreneur/saas/portfolio"
                  className="flex items-center gap-2 px-4 py-2 text-gray-400 hover:text-[#fede58] transition-colors whitespace-nowrap"
                >
                  <LineChart className="w-5 h-5" />
                  <span>Portfolio d'Investissement</span>
                </Link>
              </div>
            </div>

            <div className="group relative">
              <div className="flex items-center space-x-2 text-white group-hover:text-[#fede58] transition-colors cursor-pointer">
                <Activity className="w-5 h-5 animate-pulse" />
                <span>Monitoring</span>
              </div>
              <button
                onClick={onMonitoringClick}
                className="absolute top-full left-0 mt-2 px-4 py-2 bg-black/80 backdrop-blur-sm rounded-lg text-sm text-gray-400 hover:text-[#fede58] transition-all opacity-0 group-hover:opacity-100 whitespace-nowrap transform translate-y-2 group-hover:translate-y-0"
              >
                Domain Checking
              </button>
            </div>
          </div>

          <Link 
            to="/infopreneur"
            className="flex items-center gap-2 px-6 py-2 bg-[#fede58] text-black rounded-full font-medium hover:bg-[#fede58]/90 transition-colors group"
          >
            <Home className="w-5 h-5 transition-transform group-hover:rotate-12" />
            Home
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default InfoHeader;