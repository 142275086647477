import React from 'react';
import { X, Shield, Lock, Server, AlertTriangle } from 'lucide-react';

interface SecurityModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SecurityModal({ isOpen, onClose }: SecurityModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-start justify-center pt-20"
      onClick={onClose}
    >
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm"></div>
      
      <div 
        className="relative bg-gray-900/90 backdrop-blur-md rounded-xl shadow-2xl border border-white/10 w-full max-w-4xl mx-4 max-h-[80vh] overflow-y-auto transform transition-all duration-200 animate-[fadeIn_0.2s_ease-out]"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 bg-gray-900/90 backdrop-blur-md p-6 border-b border-white/10 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-[#F7931A]">Sécurité du Bitcoin</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-8">
          <div className="text-gray-300 leading-relaxed">
            <p>
              La sécurité du Bitcoin fait référence à l'ensemble des mécanismes, protocoles et pratiques conçus pour protéger 
              le réseau Bitcoin, les transactions et les utilisateurs contre les attaques, les fraudes et les perturbations.
            </p>
          </div>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Shield className="w-6 h-6 text-[#F7931A]" />
              Principaux piliers de la sécurité
            </h3>
            <div className="space-y-6 text-gray-300">
              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Blockchain</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Décentralisation : Pas de contrôle unique, données distribuées mondialement</li>
                  <li>Immuabilité : Transactions permanentes et inaltérables</li>
                </ul>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Preuve de travail (PoW)</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Mining : Validation sécurisée par calculs complexes</li>
                  <li>Protection contre les doubles dépenses</li>
                </ul>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Cryptographie</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Clés publiques et privées pour sécuriser les transactions</li>
                  <li>Hashing SHA-256 pour l'intégrité des données</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <AlertTriangle className="w-6 h-6 text-[#F7931A]" />
              Protections contre les attaques
            </h3>
            <div className="space-y-4 text-gray-300">
              <div>
                <h4 className="font-medium text-white mb-2">Attaque des 51%</h4>
                <p>
                  Protection par la taille et la décentralisation du réseau, rendant une telle attaque 
                  extrêmement coûteuse et peu probable.
                </p>
              </div>

              <div>
                <h4 className="font-medium text-white mb-2">Double dépense</h4>
                <p>
                  Empêchée par la PoW et la confirmation des transactions par les mineurs.
                </p>
              </div>

              <div>
                <h4 className="font-medium text-white mb-2">Déni de service (DoS)</h4>
                <p>
                  Résistance grâce à la décentralisation et la redondance du réseau.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Lock className="w-6 h-6 text-[#F7931A]" />
              Sécurité des portefeuilles
            </h3>
            <div className="space-y-4 text-gray-300">
              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Types de portefeuilles</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Portefeuilles matériels (Ledger, Trezor) : Stockage hors ligne le plus sûr</li>
                  <li>Portefeuilles logiciels : Solution connectée avec risques modérés</li>
                  <li>Portefeuilles papier : Option hors ligne pour stockage long terme</li>
                </ul>
              </div>

              <div className="mt-4">
                <h4 className="font-medium text-white mb-2">Bonnes pratiques</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Sauvegarde sécurisée des clés privées</li>
                  <li>Utilisation de l'authentification à deux facteurs (2FA)</li>
                  <li>Mises à jour régulières des logiciels</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Server className="w-6 h-6 text-[#F7931A]" />
              Évolution et défis
            </h3>
            <div className="space-y-4 text-gray-300">
              <div>
                <h4 className="font-medium text-white mb-2">Risques actuels</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Erreurs humaines (perte de clés, phishing)</li>
                  <li>Conformité aux régulations variables selon les pays</li>
                  <li>Menaces potentielles des ordinateurs quantiques</li>
                </ul>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <p className="font-medium text-white mb-2">Conclusion</p>
                <p>
                  La sécurité du Bitcoin repose sur une combinaison robuste de technologies et de pratiques, 
                  évoluant constamment pour faire face aux nouvelles menaces tout en maintenant la fiabilité 
                  du réseau.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}