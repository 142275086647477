import React from 'react';

export const CryptoIcons = {
  USDT: () => (
    <div className="w-6 h-6 transform hover:scale-110 transition-transform duration-300">
      <svg viewBox="0 0 32 32" className="w-full h-full">
        <defs>
          <linearGradient id="usdtGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#26A17B">
              <animate attributeName="stopColor" values="#26A17B;#53AE94;#26A17B" dur="3s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#53AE94">
              <animate attributeName="stopColor" values="#53AE94;#26A17B;#53AE94" dur="3s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
          <filter id="glow">
            <feGaussianBlur stdDeviation="1" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        <circle cx="16" cy="16" r="16" fill="url(#usdtGradient)">
          <animate attributeName="opacity" values="0.9;1;0.9" dur="2s" repeatCount="indefinite" />
        </circle>
        <path
          d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117"
          fill="white"
          filter="url(#glow)"
        />
      </svg>
    </div>
  ),

  BTC: () => (
    <div className="w-6 h-6 transform hover:scale-110 transition-transform duration-300">
      <svg viewBox="0 0 32 32" className="w-full h-full">
        <defs>
          <linearGradient id="bitcoinGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#F7931A">
              <animate attributeName="stopColor" values="#F7931A;#FFAE34;#F7931A" dur="2s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#FFAE34">
              <animate attributeName="stopColor" values="#FFAE34;#F7931A;#FFAE34" dur="2s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
        <circle cx="16" cy="16" r="16" fill="url(#bitcoinGradient)">
          <animate attributeName="opacity" values="0.8;1;0.8" dur="2s" repeatCount="indefinite" />
        </circle>
        <path
          d="M22.5 14.5c.4-2.7-1.7-4.1-4.5-5.1l.9-3.7-2.2-.6-.9 3.6c-.6-.1-1.2-.3-1.8-.4l.9-3.6L12.7 4l-.9 3.7c-.5-.1-1-.2-1.4-.3l-3.1-.8-.6 2.4s1.7.4 1.6.4c.9.2 1.1.8 1 1.3l-1 4.3c.1 0 .2 0 .3.1-.1 0-.2-.1-.3-.1l-1.5 5.9c-.1.3-.4.7-1.1.5 0 .1-1.6-.4-1.6-.4L4 24.3l2.9.7c.5.1 1.1.3 1.6.4l-.9 3.7 2.2.6.9-3.7c.6.2 1.2.3 1.8.4l-.9 3.7 2.2.6.9-3.7c3.8.7 6.6.4 7.8-3 1-2.7-.1-4.3-2-5.3 1.4-.3 2.5-1.3 2.8-3.3zm-5 7.2c-.7 2.7-5.4 1.2-6.9.9l1.2-5c1.5.4 6.4 1.2 5.7 4.1zm.7-7.1c-.6 2.5-4.5 1.2-5.8.9l1.1-4.5c1.3.3 5.4 1 4.7 3.6z"
          fill="white"
        />
      </svg>
    </div>
  ),

  ETH: () => (
    <div className="w-6 h-6 transform hover:scale-110 transition-transform duration-300">
      <svg viewBox="0 0 32 32" className="w-full h-full">
        <defs>
          <linearGradient id="ethGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#627EEA">
              <animate attributeName="stopColor" values="#627EEA;#8799F0;#627EEA" dur="2s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#8799F0">
              <animate attributeName="stopColor" values="#8799F0;#627EEA;#8799F0" dur="2s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
        <circle cx="16" cy="16" r="16" fill="url(#ethGradient)">
          <animate attributeName="opacity" values="0.8;1;0.8" dur="2s" repeatCount="indefinite" />
        </circle>
        <g fill="#FFF" fillRule="nonzero">
          <path fillOpacity=".602" d="M16.498 4v8.87l7.497 3.35z" />
          <path d="M16.498 4L9 16.22l7.498-3.35z" />
          <path fillOpacity=".602" d="M16.498 21.968v6.027L24 17.616z" />
          <path d="M16.498 27.995v-6.028L9 17.616z" />
        </g>
      </svg>
    </div>
  ),

  SOL: () => (
    <div className="w-6 h-6 transform hover:scale-110 transition-transform duration-300">
      <svg viewBox="0 0 32 32" className="w-full h-full">
        <defs>
          <linearGradient id="solanaGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#9945FF">
              <animate attributeName="stopColor" values="#9945FF;#14F195;#9945FF" dur="2s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#14F195">
              <animate attributeName="stopColor" values="#14F195;#9945FF;#14F195" dur="2s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
        <circle cx="16" cy="16" r="16" fill="url(#solanaGradient)">
          <animate attributeName="opacity" values="0.8;1;0.8" dur="2s" repeatCount="indefinite" />
        </circle>
        <g transform="translate(8, 8)" fill="#FFF">
          <path d="M3.2 8.2L7 4.4c.2-.2.5-.4.8-.4h8.4c.3 0 .5.2.5.4 0 .1 0 .2-.1.3l-3.8 3.8c-.2.2-.5.4-.8.4H3.6c-.3 0-.5-.2-.5-.4 0-.1 0-.2.1-.3zm0 6l3.8-3.8c.2-.2.5-.4.8-.4h8.4c.3 0 .5.2.5.4 0 .1 0 .2-.1.3l-3.8 3.8c-.2.2-.5.4-.8.4H3.6c-.3 0-.5-.2-.5-.4 0-.1 0-.2.1-.3zm12.2-9.8L11.6.6c-.2-.2-.5-.4-.8-.4H2.4c-.3 0-.5.2-.5.4 0 .1 0 .2.1.3l3.8 3.8c.2.2.5.4.8.4h8.4c.3 0 .5-.2.5-.4 0-.1 0-.2-.1-.3z" />
        </g>
      </svg>
    </div>
  ),

  XRP: () => (
    <div className="w-6 h-6 transform hover:scale-110 transition-transform duration-300">
      <svg viewBox="0 0 32 32" className="w-full h-full">
        <defs>
          <linearGradient id="xrpGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#23292F">
              <animate attributeName="stopColor" values="#23292F;#404040;#23292F" dur="2s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#404040">
              <animate attributeName="stopColor" values="#404040;#23292F;#404040" dur="2s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
        <circle cx="16" cy="16" r="16" fill="url(#xrpGradient)">
          <animate attributeName="opacity" values="0.8;1;0.8" dur="2s" repeatCount="indefinite" />
        </circle>
        <path
          d="M21 8l-5 8-3-4h-3l4.5 6L9 26h3l5-8 5 8h3l-6-8 6-10z"
          fill="white"
        />
      </svg>
    </div>
  )
};