import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getBitcoinData } from '../../services/bitcoin';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function BitcoinSupply() {
  const [supplyData, setSupplyData] = useState({
    circulating: 0,
    total: 21000000,
    remaining: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSupplyData = async () => {
      try {
        const data = await getBitcoinData();
        const circulating = Math.floor(data.circulating_supply);
        const remaining = 21000000 - circulating;
        
        setSupplyData({
          circulating,
          total: 21000000,
          remaining
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des données de supply:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSupplyData();
    const interval = setInterval(fetchSupplyData, 60000);
    return () => clearInterval(interval);
  }, []);

  const chartData = {
    labels: ['En circulation', 'Restant à miner'],
    datasets: [
      {
        data: [supplyData.circulating, supplyData.remaining],
        backgroundColor: [
          '#F7931A',
          'rgba(255, 255, 255, 0.1)',
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          color: 'white',
        },
      },
    },
  };

  if (loading) {
    return (
      <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm animate-pulse">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="h-64 bg-white/10 rounded"></div>
          <div className="space-y-4">
            <div className="h-8 bg-white/10 rounded w-3/4"></div>
            <div className="h-12 bg-white/10 rounded"></div>
            <div className="h-8 bg-white/10 rounded w-1/2"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-xl font-semibold mb-4">Distribution des Bitcoins</h3>
          <div className="w-full max-w-md mx-auto">
            <Doughnut data={chartData} options={options} />
          </div>
        </div>
        <div className="space-y-6">
          <div>
            <h4 className="text-lg font-medium mb-2">Bitcoin en circulation</h4>
            <p className="text-3xl font-bold">{supplyData.circulating.toLocaleString()} BTC</p>
            <p className="text-sm text-gray-400">{((supplyData.circulating / supplyData.total) * 100).toFixed(2)}% du total</p>
          </div>
          <div>
            <h4 className="text-lg font-medium mb-2">Restant à miner</h4>
            <p className="text-3xl font-bold">{supplyData.remaining.toLocaleString()} BTC</p>
            <p className="text-sm text-gray-400">{((supplyData.remaining / supplyData.total) * 100).toFixed(2)}% du total</p>
          </div>
          <div>
            <h4 className="text-lg font-medium mb-2">Supply maximale</h4>
            <p className="text-3xl font-bold">{supplyData.total.toLocaleString()} BTC</p>
            <p className="text-sm text-gray-400">Limite finale</p>
          </div>
        </div>
      </div>
    </div>
  );
}