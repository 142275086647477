import React from 'react';
import { X, DollarSign, TrendingUp, Clock, BarChart } from 'lucide-react';

interface DCAModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function DCAModal({ isOpen, onClose }: DCAModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-start justify-center pt-20"
      onClick={onClose}
    >
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm"></div>
      
      <div 
        className="relative bg-gray-900/90 backdrop-blur-md rounded-xl shadow-2xl border border-white/10 w-full max-w-4xl mx-4 max-h-[80vh] overflow-y-auto transform transition-all duration-200 animate-[fadeIn_0.2s_ease-out]"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 bg-gray-900/90 backdrop-blur-md p-6 border-b border-white/10 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-[#F7931A]">Dollar-Cost Averaging (DCA)</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-8">
          <div className="text-gray-300 leading-relaxed">
            <p>
              Le DCA (Dollar-Cost Averaging) est une stratégie d'investissement qui consiste à investir une somme fixe 
              à intervalles réguliers dans un actif, comme le Bitcoin, indépendamment de son prix. Cette approche permet 
              de lisser le coût d'acquisition et de réduire l'impact de la volatilité du marché.
            </p>
          </div>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Clock className="w-6 h-6 text-[#F7931A]" />
              Comment fonctionne le DCA ?
            </h3>
            <div className="space-y-4 text-gray-300">
              <div>
                <h4 className="font-medium text-white mb-2">Investissement régulier</h4>
                <p>
                  Au lieu d'investir une grosse somme en une seule fois, l'investisseur achète une petite quantité 
                  de Bitcoin à intervalles réguliers (par exemple, tous les mois).
                </p>
              </div>

              <div>
                <h4 className="font-medium text-white mb-2">Indépendance du prix</h4>
                <p>
                  L'investisseur achète du Bitcoin quel que soit son prix à ce moment-là. Cela permet d'acheter plus 
                  de Bitcoin lorsque le prix est bas et moins lorsque le prix est élevé.
                </p>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Exemple</h4>
                <p className="mb-2">Budget mensuel : 100 $</p>
                <p className="mb-2">Prix du Bitcoin :</p>
                <ul className="space-y-2">
                  <li>Mois 1 : 20 000 $ → 0,005 BTC</li>
                  <li>Mois 2 : 25 000 $ → 0,004 BTC</li>
                  <li>Mois 3 : 18 000 $ → 0,00556 BTC</li>
                  <li>Total investi : 300 $ pour 0,01456 BTC</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <TrendingUp className="w-6 h-6 text-[#F7931A]" />
              Avantages du DCA
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-300">
              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Réduction de la volatilité</h4>
                <p>Le DCA permet de lisser les fluctuations du marché en évitant d'investir une grosse somme au mauvais moment.</p>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Discipline</h4>
                <p>Cette stratégie encourage une approche disciplinée et régulière, sans essayer de "timer le marché".</p>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Accessibilité</h4>
                <p>Le DCA est accessible aux petits investisseurs, car il ne nécessite pas un capital initial important.</p>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Réduction du stress</h4>
                <p>En investissant de manière régulière, l'investisseur évite le stress lié aux fluctuations à court terme du marché.</p>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <BarChart className="w-6 h-6 text-[#F7931A]" />
              Comment mettre en place un DCA ?
            </h3>
            <div className="space-y-4 text-gray-300">
              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Étapes pratiques</h4>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>Choisir une plateforme d'échange fiable et sécurisée</li>
                  <li>Définir un budget fixe à investir régulièrement</li>
                  <li>Configurer des achats automatiques si possible</li>
                  <li>Suivre et ajuster la stratégie selon les objectifs</li>
                </ol>
              </div>

              <div>
                <h4 className="font-medium text-white mb-2">Points d'attention</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Tenir compte des frais de transaction</li>
                  <li>Rester fidèle à la stratégie malgré les fluctuations</li>
                  <li>Conserver ses Bitcoin de manière sécurisée</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <DollarSign className="w-6 h-6 text-[#F7931A]" />
              Exemple pratique sur 12 mois
            </h3>
            <div className="bg-white/5 rounded-xl p-4 text-gray-300">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <h4 className="font-medium text-white mb-2">Paramètres</h4>
                  <ul className="space-y-2">
                    <li>Budget mensuel : 100 $</li>
                    <li>Période : 12 mois</li>
                    <li>Prix du Bitcoin : 15 000 $ - 30 000 $</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-medium text-white mb-2">Résultats</h4>
                  <ul className="space-y-2">
                    <li>Total investi : 1 200 $</li>
                    <li>Bitcoin acquis : 0,05 BTC</li>
                    <li>Prix moyen d'achat : 24 000 $ par BTC</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}