import axios from 'axios';

const BYBIT_API_URL = 'https://api.bybit.com';

export interface NewListing {
  symbol: string;
  name: string;
  price: number;
  change24h: number;
  volume24h: number;
  launchDate: string;
  description?: string;
}

export const getNewListings = async (): Promise<NewListing[]> => {
  try {
    const response = await axios.get(`${BYBIT_API_URL}/v5/market/tickers`, {
      params: {
        category: 'spot'
      }
    });

    // Filter and transform the data to get new listings
    const tickers = response.data.result.list;
    const newListings = tickers
      .filter((ticker: any) => {
        // Filter for tokens listed in the last 30 days
        const symbol = ticker.symbol;
        const isNew = symbol.endsWith('USDT'); // Only USDT pairs
        return isNew;
      })
      .map((ticker: any) => ({
        symbol: ticker.symbol.replace('USDT', ''),
        name: ticker.symbol.replace('USDT', ''),
        price: parseFloat(ticker.lastPrice),
        change24h: parseFloat(ticker.price24hPcnt) * 100,
        volume24h: parseFloat(ticker.volume24h),
        launchDate: new Date().toISOString() // Bybit doesn't provide listing date, using current date
      }))
      .sort((a: NewListing, b: NewListing) => b.change24h - a.change24h)
      .slice(0, 10); // Get top 10 performers

    return newListings;
  } catch (error) {
    console.error('Error fetching new listings:', error);
    throw new Error('Failed to fetch new listings');
  }
};

export const getMarketOverview = async () => {
  try {
    const response = await axios.get(`${BYBIT_API_URL}/v5/market/tickers`, {
      params: {
        category: 'spot'
      }
    });

    const tickers = response.data.result.list;
    const totalVolume = tickers.reduce((acc: number, ticker: any) => 
      acc + parseFloat(ticker.volume24h), 0
    );

    const gainers = tickers.filter((ticker: any) => 
      parseFloat(ticker.price24hPcnt) > 0
    ).length;

    return {
      totalVolume,
      gainers,
      totalPairs: tickers.length
    };
  } catch (error) {
    console.error('Error fetching market overview:', error);
    throw new Error('Failed to fetch market overview');
  }
};