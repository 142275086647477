export const testimonialsList = [
  {
    name: "Marie Laurent",
    role: "Développeuse Frontend",
    text: "La qualité des cours et le support de la communauté m'ont permis de changer de carrière en seulement 6 mois.",
    rating: 5,
    avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80&w=100"
  },
  {
    name: "Thomas Dubois",
    role: "Étudiant en informatique",
    text: "Les projets pratiques sont exactement ce dont j'avais besoin pour consolider mes connaissances théoriques.",
    rating: 5,
    avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&q=80&w=100"
  },
  {
    name: "Sophie Martin",
    role: "Freelance Full-Stack",
    text: "L'approche progressive et la qualité du contenu m'ont vraiment impressionnée. Je recommande vivement !",
    rating: 5,
    avatar: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&q=80&w=100"
  }
];