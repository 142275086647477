import React, { useState } from 'react';
import { MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';
import LocationModal from './LocationModal';

interface HeroProps {
  onStartNow: () => void;
}

export default function Hero({ onStartNow }: HeroProps) {
  const [showLocation, setShowLocation] = useState(false);

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center text-black overflow-hidden">
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-[#fede58]/80 mix-blend-overlay z-10"></div>
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src="https://gbgroup.xyz/upvideo/info-4644.webm" type="video/webm" />
        </video>
      </div>
      
      <div className="relative z-10 text-center px-4">
        <img 
          src="https://avatars.githubusercontent.com/u/100054028?v=4"
          alt="GB Group Logo"
          className="w-32 h-32 mx-auto mb-12 rounded-full shadow-2xl ring-4 ring-white/50"
        />
        
        <h1 className="text-6xl font-bold mb-6 animate-[fadeIn_1s_ease-out]">
          GB Group Event
        </h1>
        
        <Link 
          to="/infopreneur"
          className="text-3xl mb-8 animate-[fadeIn_1s_ease-out_0.2s] opacity-0 [animation-fill-mode:forwards] hover:text-[#fede58] transition-colors inline-block"
        >
          Infopreneur 📈
        </Link>
        
        <div className="flex items-center justify-center gap-2 mb-12 text-sm animate-[fadeIn_1s_ease-out_0.4s] opacity-0 [animation-fill-mode:forwards]">
          <MapPin className="w-4 h-4" />
          <button
            onClick={() => setShowLocation(true)}
            className="hover:underline focus:outline-none focus:text-gray-700 transition-colors"
          >
            Lexington
          </button>
        </div>
        
        <button 
          onClick={onStartNow}
          className="bg-black text-white font-bold py-4 px-10 rounded-full text-lg hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105 shadow-xl animate-[fadeIn_1s_ease-out_0.6s] opacity-0 [animation-fill-mode:forwards]"
        >
          Commencer maintenant
        </button>
      </div>

      <LocationModal 
        isOpen={showLocation}
        onClose={() => setShowLocation(false)}
      />
    </div>
  );
}