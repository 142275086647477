import React from 'react';
import { Code2, Sparkles } from 'lucide-react';

export function CodeHero() {
  return (
    <div className="relative min-h-[80vh] flex items-center">
      {/* Video Background */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-black/50 backdrop-blur-sm z-10"></div>
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src="https://gbgroup.xyz/upvideo/4884-ffb9-4890-85b0-48288390a6d6.webm" type="video/webm" />
        </video>
      </div>

      {/* Content */}
      <div className="container mx-auto px-4 relative z-20">
        <div className="max-w-3xl mx-auto text-center">
          <div className="inline-flex items-center gap-2 bg-white/10 px-4 py-2 rounded-full mb-8">
            <Sparkles className="w-5 h-5 text-yellow-400" />
            <span>Apprenez à coder comme un pro</span>
          </div>
          <h1 className="text-6xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            Maîtrisez le Code avec Excellence
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            Des cours interactifs, des projets pratiques et un accompagnement personnalisé pour devenir développeur.
          </p>
          <div className="flex gap-4 justify-center">
            <button className="bg-blue-600 hover:bg-blue-700 px-8 py-3 rounded-lg font-medium flex items-center gap-2">
              <Code2 className="w-5 h-5" />
              Commencer gratuitement
            </button>
            <button className="bg-white/10 hover:bg-white/20 px-8 py-3 rounded-lg font-medium">
              Voir les cours
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}