import React from 'react';
import { X, Calculator, DollarSign, Smartphone, ArrowRight } from 'lucide-react';

interface ConverterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ConverterModal({ isOpen, onClose }: ConverterModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-start justify-center pt-20"
      onClick={onClose}
    >
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm"></div>
      
      <div 
        className="relative bg-gray-900/90 backdrop-blur-md rounded-xl shadow-2xl border border-white/10 w-full max-w-4xl mx-4 max-h-[80vh] overflow-y-auto transform transition-all duration-200 animate-[fadeIn_0.2s_ease-out]"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 bg-gray-900/90 backdrop-blur-md p-6 border-b border-white/10 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-[#F7931A]">Convertisseur USD/BTC</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-8">
          <div className="text-gray-300 leading-relaxed">
            <p>
              Pour convertir des USD (dollars américains) en BTC (Bitcoin), vous pouvez utiliser un convertisseur 
              en ligne ou effectuer le calcul manuellement en utilisant le taux de change actuel du Bitcoin.
            </p>
          </div>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Calculator className="w-6 h-6 text-[#F7931A]" />
              Calcul manuel
            </h3>
            <div className="space-y-4 text-gray-300">
              <div>
                <h4 className="font-medium text-white mb-2">Formule de conversion</h4>
                <div className="bg-white/5 rounded-xl p-4 text-center">
                  <p className="mb-2">Montant en BTC = Montant en USD / Prix du BTC en USD</p>
                  <div className="mt-4 p-4 bg-white/5 rounded-lg">
                    <p className="font-mono">
                      Exemple : 1000 USD ÷ 30000 USD = 0.0333 BTC
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <DollarSign className="w-6 h-6 text-[#F7931A]" />
              Plateformes de conversion
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-300">
              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">CoinMarketCap</h4>
                <p>Convertisseur intégré avec données en temps réel</p>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Coinbase</h4>
                <p>Plateforme d'échange avec convertisseur intégré</p>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Binance</h4>
                <p>Convertisseur et plateforme d'échange complète</p>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">CoinGecko</h4>
                <p>Données de marché et outils de conversion</p>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Smartphone className="w-6 h-6 text-[#F7931A]" />
              Applications mobiles
            </h3>
            <div className="space-y-4 text-gray-300">
              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Applications recommandées</h4>
                <ul className="space-y-4">
                  <li className="flex items-start gap-4">
                    <div>
                      <p className="font-medium">Blockfolio</p>
                      <p className="text-sm text-gray-400">iOS et Android</p>
                      <p className="mt-1">Suivi des portefeuilles et convertisseur de devises</p>
                    </div>
                  </li>
                  <li className="flex items-start gap-4">
                    <div>
                      <p className="font-medium">Delta</p>
                      <p className="text-sm text-gray-400">iOS et Android</p>
                      <p className="mt-1">Gestion de portefeuille et conversion de devises</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <ArrowRight className="w-6 h-6 text-[#F7931A]" />
              Exemple pratique
            </h3>
            <div className="bg-white/5 rounded-xl p-4 text-gray-300">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <h4 className="font-medium text-white mb-2">Données</h4>
                  <ul className="space-y-2">
                    <li>Montant en USD : 500 $</li>
                    <li>Prix du BTC : 25 000 $</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-medium text-white mb-2">Calcul</h4>
                  <div className="font-mono">
                    <p>500 ÷ 25000 = 0.02 BTC</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}