import React from 'react';
import Hero from '../components/Hero';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();

  const handleStartNow = () => {
    navigate('/infopreneur#reservation');
  };

  return (
    <>
      <Hero onStartNow={handleStartNow} />
    </>
  );
}