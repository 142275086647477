import React from 'react';
import { X } from 'lucide-react';

interface CertificationsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CertificationsModal({ isOpen, onClose }: CertificationsModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-gray-900/95 backdrop-blur-md w-full max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 text-white"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Certifications Reconnues</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="space-y-8">
          <p className="text-gray-300">
            Obtenir des certifications reconnues est un excellent moyen de valoriser vos compétences 
            et de renforcer votre profil professionnel.
          </p>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">1. Développement Web</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">a. Certificats Généraux</h4>
                <div className="space-y-4">
                  <div>
                    <h5 className="text-green-400">freeCodeCamp Certifications</h5>
                    <ul className="list-disc pl-6 text-gray-300 space-y-1">
                      <li>Totalement gratuits</li>
                      <li>Développement Web (HTML, CSS, JavaScript)</li>
                      <li>Frontend Libraries (React, Redux)</li>
                      <li>Développement Backend</li>
                      <li>Data Visualization</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <h4 className="font-semibold text-white">b. Développement Avancé</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Microsoft Certified: Azure Developer Associate</li>
                  <li>Google Mobile Web Specialist</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">2. Programmation Générale</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">a. Python</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>PCAP: Python Certified Associate Programmer</li>
                  <li>DataCamp Python Certifications</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">b. Java</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Oracle Certified Professional, Java SE Programmer</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">3. Science des Données et IA</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">a. Science des Données</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>IBM Data Science Professional Certificate</li>
                  <li>Microsoft Certified: Data Scientist Associate</li>
                  <li>Google Data Analytics Professional Certificate</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">b. Apprentissage Automatique</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>TensorFlow Developer Certificate</li>
                  <li>Deep Learning Specialization by Andrew Ng</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">4. Sécurité Informatique</h3>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>CompTIA Security+</li>
              <li>Certified Ethical Hacker (CEH)</li>
              <li>OSCP (Offensive Security Certified Professional)</li>
              <li>Cisco Certified CyberOps Associate</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">5. Cloud Computing</h3>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>AWS Certified Solutions Architect</li>
              <li>Microsoft Azure Fundamentals</li>
              <li>Google Cloud Professional Cloud Architect</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">Conseils pour Choisir une Certification</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">Définissez vos objectifs professionnels</h4>
                <p className="text-gray-300">Souhaitez-vous améliorer vos compétences actuelles ou changer de domaine ?</p>
              </div>

              <div>
                <h4 className="font-semibold text-white">Analysez la demande sur le marché</h4>
                <p className="text-gray-300">Consultez des offres d'emploi pour identifier les certifications recherchées.</p>
              </div>

              <div>
                <h4 className="font-semibold text-white">Préparez-vous efficacement</h4>
                <p className="text-gray-300">Suivez des cours en ligne pour vous entraîner avant de passer l'examen.</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}