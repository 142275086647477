import React from 'react';
import { X } from 'lucide-react';

interface FreeTierModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function FreeTierModal({ isOpen, onClose }: FreeTierModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-gray-900/95 backdrop-blur-md w-full max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 text-white"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Accès Gratuit aux Ressources</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="space-y-8">
          <p className="text-gray-300">
            Voici une liste de plateformes et de ressources qui combinent cours de base, éditeurs de code en ligne, 
            forums communautaires, et projets simples pour vous aider à progresser efficacement.
          </p>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">1. Plateformes Tout-en-Un</h3>
            <div className="space-y-6 pl-4">
              <div>
                <h4 className="font-semibold text-white">a. freeCodeCamp</h4>
                <div className="space-y-2">
                  <p className="text-gray-300">Cours de base :</p>
                  <ul className="list-disc pl-6 text-gray-300 space-y-1">
                    <li>Développement Web (HTML, CSS, JavaScript)</li>
                    <li>Bibliothèques Frontend (React)</li>
                    <li>Backend et APIs</li>
                  </ul>
                  <p className="text-gray-300">Caractéristiques :</p>
                  <ul className="list-disc pl-6 text-gray-300 space-y-1">
                    <li>Éditeur intégré : Vous codez directement dans le navigateur</li>
                    <li>Forum communautaire : forum.freecodecamp.org</li>
                    <li>Projets : Certifications basées sur des projets simples</li>
                  </ul>
                </div>
              </div>

              <div>
                <h4 className="font-semibold text-white">b. The Odin Project</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Développement Web complet (HTML, CSS, JavaScript, Node.js)</li>
                  <li>Liens vers des outils en ligne ou des IDE locaux</li>
                  <li>Forum communautaire sur Discord</li>
                  <li>Projets simples comme la construction d'un clone de Google</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">c. Sololearn</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Langages variés (Python, JavaScript, C++)</li>
                  <li>Éditeur de code en ligne intégré</li>
                  <li>Forum communautaire actif</li>
                  <li>Exercices interactifs pour chaque concept</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">2. Plateformes d'Apprentissage Actif</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">a. CodePen</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Guides pour HTML, CSS et JavaScript</li>
                  <li>Idéal pour expérimenter avec des projets web</li>
                  <li>Créez des petits prototypes interactifs</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">b. Kaggle</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Science des données et Python</li>
                  <li>Notebooks Python pour coder en ligne</li>
                  <li>Mini-datasets pour créer des visualisations</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">3. Forums Communautaires</h3>
            <div className="space-y-4 pl-4">
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Stack Overflow : Questions techniques spécifiques</li>
                <li>Reddit : r/learnprogramming, r/webdev</li>
                <li>Discord : Serveurs pour Python, JavaScript, développement web</li>
                <li>Forums dédiés : freeCodeCamp, The Odin Project</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">4. Suggestions de Projets Simples</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">HTML/CSS/JavaScript</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Page de portfolio</li>
                  <li>Générateur de citations aléatoires</li>
                  <li>Horloge digitale</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">Python</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Convertisseur de devises</li>
                  <li>Générateur de mot de passe</li>
                  <li>Calculatrice simple</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">Conseils pour une Progression Efficace</h3>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Combinez apprentissage et pratique : Appliquez immédiatement ce que vous apprenez</li>
              <li>Participez à une communauté : Posez des questions et aidez les autres</li>
              <li>Suivez un parcours structuré : Utilisez des plateformes comme The Odin Project</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}