import React from 'react';
import { FORMATION_DESCRIPTION } from '../constants/content';

export default function InfoHero() {
  const handleStartNow = () => {
    document.getElementById('reservation')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative h-screen">
      <div className="absolute inset-0 bg-black/50 z-10"></div>
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover"
      >
        <source src="https://gbgroup.xyz/upvideo/info-4644.webm" type="video/webm" />
      </video>
      
      <div className="relative z-20 container mx-auto px-4 h-full flex flex-col items-center justify-center text-center">
        <div className="max-w-4xl mx-auto">
          <h1 
            className="text-6xl font-bold mb-8 bg-gradient-to-r from-[#fede58] to-yellow-500 bg-clip-text text-transparent transition-all duration-500 animate-[fadeIn_0.5s_ease-out]"
            style={{ fontFamily: "'Montserrat', sans-serif" }}
          >
            Infopreneur
          </h1>
          
          <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-12 animate-[fadeIn_0.5s_ease-out_0.2s] opacity-0 [animation-fill-mode:forwards]">
            {FORMATION_DESCRIPTION}
          </p>

          <button
            onClick={handleStartNow}
            className="bg-[#fede58] text-black font-bold py-4 px-10 rounded-full text-lg hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105 shadow-xl animate-[fadeIn_1s_ease-out_0.6s] opacity-0 [animation-fill-mode:forwards]"
          >
            Commencer maintenant
          </button>
        </div>
      </div>
    </div>
  );
}