import React from 'react';
import { X, Code, Database, Shield, Server, GitBranch } from 'lucide-react';

interface ApiModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ApiModal({ isOpen, onClose }: ApiModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-gray-900/95 backdrop-blur-md w-full max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 text-white"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">API Bitcoin</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="prose prose-invert max-w-none">
          <p className="text-gray-300 text-lg">
            Une API (Application Programming Interface) pour le Bitcoin est une interface qui permet aux développeurs 
            d'interagir avec le réseau Bitcoin, d'accéder à des données sur la blockchain, d'effectuer des transactions, 
            ou d'intégrer des fonctionnalités liées au Bitcoin dans des applications ou des services.
          </p>

          <section className="mt-8">
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Code className="w-6 h-6 text-blue-400" />
              1. Fonctionnalités d'une API Bitcoin
            </h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Accès aux données de la blockchain</h4>
                <ul className="list-disc pl-4 space-y-1 text-gray-300">
                  <li>Obtenir des informations sur les blocs, transactions et adresses</li>
                  <li>Suivre les confirmations de transactions</li>
                </ul>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Envoi et réception de Bitcoin</h4>
                <ul className="list-disc pl-4 space-y-1 text-gray-300">
                  <li>Créer des adresses Bitcoin pour recevoir des fonds</li>
                  <li>Envoyer des transactions Bitcoin</li>
                </ul>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Surveillance du réseau</h4>
                <ul className="list-disc pl-4 space-y-1 text-gray-300">
                  <li>Statistiques en temps réel (taux de hachage, difficulté)</li>
                  <li>Suivi des frais de transaction recommandés</li>
                </ul>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Intégration avec des services tiers</h4>
                <ul className="list-disc pl-4 space-y-1 text-gray-300">
                  <li>Intégration de fonctionnalités Bitcoin dans les applications</li>
                  <li>Interaction avec les échanges de cryptomonnaies</li>
                </ul>
              </div>
            </div>
          </section>

          <section className="mt-8">
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <GitBranch className="w-6 h-6 text-blue-400" />
              2. Types d'API Bitcoin
            </h3>

            <div className="space-y-4">
              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">API de nœud complet</h4>
                <p className="text-gray-300 mb-2">Permet d'interagir directement avec un nœud Bitcoin (ex : Bitcoin Core).</p>
                <p className="text-sm text-gray-400">Exemple : API JSON-RPC de Bitcoin Core</p>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">API d'explorateur de blockchain</h4>
                <p className="text-gray-300 mb-2">Fournit des données sur la blockchain sans nécessiter de nœud complet.</p>
                <p className="text-sm text-gray-400">Exemples : API de Blockchain.com ou BlockCypher</p>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">API d'échange de cryptomonnaies</h4>
                <p className="text-gray-300 mb-2">Permet d'interagir avec des plateformes d'échange pour acheter, vendre ou échanger du Bitcoin.</p>
                <p className="text-sm text-gray-400">Exemples : API de Coinbase, Binance, ou Kraken</p>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">API de paiement</h4>
                <p className="text-gray-300 mb-2">Facilite l'intégration de paiements en Bitcoin dans des applications ou sites web.</p>
                <p className="text-sm text-gray-400">Exemples : API de BitPay ou CoinGate</p>
              </div>
            </div>
          </section>

          <section className="mt-8">
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Server className="w-6 h-6 text-blue-400" />
              3. Comment utiliser une API Bitcoin
            </h3>

            <div className="space-y-4">
              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Obtenir une clé API</h4>
                <p className="text-gray-300">La plupart des API nécessitent une clé API pour authentifier les requêtes.</p>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Exemple de requête</h4>
                <code className="block bg-black/30 p-3 rounded text-sm overflow-x-auto">
                  GET https://blockchain.info/rawtx/TRANSACTION_ID?api_code=VOTRE_CLE_API
                </code>
              </div>
            </div>
          </section>

          <section className="mt-8">
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Database className="w-6 h-6 text-blue-400" />
              4. API Bitcoin populaires
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Bitcoin Core JSON-RPC</h4>
                <p className="text-gray-300">API pour interagir avec un nœud Bitcoin Core</p>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Blockchain.com API</h4>
                <p className="text-gray-300">API pour accéder aux données de la blockchain Bitcoin</p>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">BlockCypher API</h4>
                <p className="text-gray-300">API pour explorer la blockchain et effectuer des transactions</p>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Coinbase API</h4>
                <p className="text-gray-300">API pour interagir avec la plateforme Coinbase</p>
              </div>
            </div>
          </section>

          <section className="mt-8">
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Shield className="w-6 h-6 text-blue-400" />
              5. Bonnes pratiques
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Sécurité</h4>
                <ul className="list-disc pl-4 space-y-1 text-gray-300">
                  <li>Protégez votre clé API</li>
                  <li>Utilisez HTTPS pour les communications</li>
                </ul>
              </div>

              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="font-medium text-blue-400 mb-2">Limites de taux</h4>
                <ul className="list-disc pl-4 space-y-1 text-gray-300">
                  <li>Respectez les limites de taux (rate limits)</li>
                  <li>Gérez les erreurs et les réponses vides</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}