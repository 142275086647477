import React from 'react';
import { X } from 'lucide-react';

interface StructuredCoursesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function StructuredCoursesModal({ isOpen, onClose }: StructuredCoursesModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-gray-900/95 backdrop-blur-md w-full max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 text-white"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Parcours d'Apprentissage Structuré</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="space-y-8">
          <p className="text-gray-300">
            Si vous recherchez des cours structurés avec un parcours d'apprentissage progressif pour 
            évoluer du niveau débutant à expert, voici une sélection de ressources et plateformes 
            adaptées à divers domaines et langages.
          </p>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">1. Développement Web</h3>
            <div className="space-y-6 pl-4">
              <div>
                <h4 className="font-semibold text-white mb-2">a. HTML, CSS, et JavaScript</h4>
                <div className="space-y-4">
                  <div>
                    <h5 className="text-green-400">Débutant</h5>
                    <ul className="list-disc pl-6 text-gray-300 space-y-1">
                      <li>freeCodeCamp - Responsive Web Design : Couvre HTML, CSS et principes de design.</li>
                      <li>MDN Web Docs : Documentation détaillée avec des guides interactifs.</li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="text-yellow-400">Intermédiaire</h5>
                    <ul className="list-disc pl-6 text-gray-300 space-y-1">
                      <li>The Odin Project : Construction de projets complets avec HTML, CSS, et JavaScript.</li>
                      <li>JavaScript.info : Ressource approfondie pour maîtriser JavaScript.</li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="text-red-400">Expert</h5>
                    <ul className="list-disc pl-6 text-gray-300 space-y-1">
                      <li>React Documentation, Vue Mastery</li>
                      <li>Frontend Masters : Cours avancés pour front-end et JavaScript.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">2. Programmation Générale</h3>
            <div className="space-y-6 pl-4">
              <div>
                <h4 className="font-semibold text-white mb-2">a. Python</h4>
                <div className="space-y-4">
                  <div>
                    <h5 className="text-green-400">Débutant</h5>
                    <ul className="list-disc pl-6 text-gray-300 space-y-1">
                      <li>Automate the Boring Stuff with Python</li>
                      <li>Python for Everybody (Coursera)</li>
                      <li>Sololearn Python Course</li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="text-yellow-400">Intermédiaire</h5>
                    <ul className="list-disc pl-6 text-gray-300 space-y-1">
                      <li>Real Python : Tutoriels structurés avec exercices.</li>
                      <li>DataCamp : Cours sur la science des données et Python.</li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="text-red-400">Expert</h5>
                    <ul className="list-disc pl-6 text-gray-300 space-y-1">
                      <li>CS50's Introduction to Artificial Intelligence with Python</li>
                      <li>Contribuez à des projets open-source sur GitHub.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">3. Science des Données et Intelligence Artificielle</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h5 className="text-green-400">Débutant</h5>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Google's Machine Learning Crash Course</li>
                  <li>Kaggle : mini-cours pour apprendre Python, Pandas, et le Machine Learning</li>
                  <li>DataCamp : orienté vers la pratique</li>
                </ul>
              </div>
              <div>
                <h5 className="text-yellow-400">Intermédiaire</h5>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Coursera - Andrew Ng's Machine Learning</li>
                  <li>Explorez les bibliothèques comme Scikit-learn, TensorFlow, et PyTorch</li>
                </ul>
              </div>
              <div>
                <h5 className="text-red-400">Expert</h5>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>fast.ai : Apprenez l'IA avancée avec des projets concrets</li>
                  <li>Participez à des compétitions Kaggle</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">4. Développement Mobile</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">Android (Java/Kotlin)</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Android Developers Guide</li>
                  <li>Udacity - Kotlin for Android Developers</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-white">iOS (Swift)</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Hacking with Swift</li>
                  <li>Stanford's iOS Development Course</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">Conseils</h3>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Pratique régulière : La programmation s'apprend en écrivant beaucoup de code.</li>
              <li>Projets personnels : Construisez vos propres projets pour appliquer vos connaissances.</li>
              <li>Rejoignez une communauté : Participez à des forums (Reddit, Stack Overflow) ou des hackathons.</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}