export const blogPosts = [
  {
    title: "Getting Started with Web Development",
    excerpt: "Learn the fundamentals of HTML, CSS, and JavaScript to start your web development journey.",
    image: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?auto=format&fit=crop&q=80&w=1000",
    date: "March 15, 2024",
    readTime: 5
  },
  {
    title: "Understanding React Hooks",
    excerpt: "Deep dive into React Hooks and how they can simplify your component logic.",
    image: "https://images.unsplash.com/photo-1633356122544-f134324a6cee?auto=format&fit=crop&q=80&w=1000",
    date: "March 12, 2024",
    readTime: 8
  },
  {
    title: "Python for Beginners",
    excerpt: "Start your programming journey with Python - the perfect language for beginners.",
    image: "https://images.unsplash.com/photo-1526379095098-d400fd0bf935?auto=format&fit=crop&q=80&w=1000",
    date: "March 10, 2024",
    readTime: 6
  }
];