import React from 'react';
import { blogPosts } from '../../constants/learn-code/blog';

export function BlogPosts() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {blogPosts.map((post, index) => (
        <article 
          key={index}
          className="bg-white/5 rounded-xl overflow-hidden hover:transform hover:scale-105 transition-all"
        >
          <img 
            src={post.image} 
            alt={post.title} 
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <h2 className="text-xl font-bold mb-2">{post.title}</h2>
            <p className="text-gray-400 mb-4">{post.excerpt}</p>
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-500">{post.date}</span>
              <span className="text-sm text-blue-400">{post.readTime} min read</span>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
}