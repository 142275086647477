import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AppRoutes } from './routes/AppRoutes';

function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen">
        <Toaster position="top-center" />
        <AppRoutes />
      </div>
    </BrowserRouter>
  );
}

export default App;