import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BITCOIN_PRICE = 65432; // Prix actuel du Bitcoin (simulé)
const GROWTH_RATE = 0.15; // Taux de croissance annuel estimé (15%)

export default function DCASimulator() {
  const [amount, setAmount] = useState(100);
  const [frequency, setFrequency] = useState('weekly');
  const [duration, setDuration] = useState(12);
  const [chartData, setChartData] = useState<any>(null);

  // Calcul du nombre d'achats en fonction de la fréquence
  const getNumberOfPurchases = () => {
    switch (frequency) {
      case 'daily':
        return duration * 30;
      case 'weekly':
        return duration * 4;
      case 'monthly':
        return duration;
      default:
        return 0;
    }
  };

  // Calcul des résultats de la simulation
  const calculateResults = () => {
    const numberOfPurchases = getNumberOfPurchases();
    const totalInvestment = amount * numberOfPurchases;
    
    // Simulation de l'évolution du prix du Bitcoin
    const monthlyData = Array.from({ length: duration }, (_, i) => {
      const monthlyGrowth = Math.pow(1 + GROWTH_RATE, i / 12);
      return BITCOIN_PRICE * monthlyGrowth;
    });

    // Calcul de la valeur finale estimée
    const btcAccumulated = totalInvestment / BITCOIN_PRICE;
    const estimatedValue = btcAccumulated * monthlyData[monthlyData.length - 1];
    const profit = estimatedValue - totalInvestment;
    const profitPercentage = (profit / totalInvestment) * 100;

    return {
      totalInvestment,
      estimatedValue,
      profit,
      profitPercentage,
      btcAccumulated,
      monthlyData
    };
  };

  // Mise à jour du graphique
  useEffect(() => {
    const results = calculateResults();
    const labels = Array.from({ length: duration }, (_, i) => `Mois ${i + 1}`);

    setChartData({
      labels,
      datasets: [
        {
          label: 'Valeur du portefeuille',
          data: results.monthlyData.map((price, i) => 
            (results.btcAccumulated / results.monthlyData.length * (i + 1)) * price
          ),
          borderColor: '#F7931A',
          backgroundColor: 'rgba(247, 147, 26, 0.1)',
          fill: true,
          tension: 0.4,
        }
      ]
    });
  }, [amount, frequency, duration]);

  const results = calculateResults();

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `${context.parsed.y.toLocaleString()} USD`;
          }
        }
      }
    },
    scales: {
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.8)',
          callback: (value: number) => `$${value.toLocaleString()}`
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.8)',
        }
      }
    }
  };

  return (
    <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Formulaire */}
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium mb-2">
              Montant par achat (USD)
            </label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(Math.max(1, Number(e.target.value)))}
              className="w-full px-4 py-2 bg-white/10 rounded-lg focus:ring-2 focus:ring-[#F7931A] border-none text-lg"
              min="1"
            />
            <p className="text-sm text-gray-400 mt-1">
              Montant investi à chaque achat
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              Fréquence d'achat
            </label>
            <select
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
              className="w-full px-4 py-2 bg-white/10 rounded-lg focus:ring-2 focus:ring-[#F7931A] border-none text-lg"
            >
              <option value="daily">Quotidien</option>
              <option value="weekly">Hebdomadaire</option>
              <option value="monthly">Mensuel</option>
            </select>
            <p className="text-sm text-gray-400 mt-1">
              À quelle fréquence souhaitez-vous acheter ?
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              Durée (mois)
            </label>
            <input
              type="range"
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
              className="w-full h-2 bg-white/10 rounded-lg appearance-none cursor-pointer accent-[#F7931A]"
              min="1"
              max="60"
            />
            <div className="flex justify-between text-sm text-gray-400 mt-1">
              <span>1 mois</span>
              <span>{duration} mois</span>
              <span>5 ans</span>
            </div>
          </div>

          {/* Résultats détaillés */}
          <div className="bg-white/5 rounded-xl p-6 space-y-4">
            <h3 className="text-xl font-semibold mb-4">Résultats de simulation</h3>
            
            <div>
              <p className="text-sm text-gray-400">Investissement total</p>
              <p className="text-2xl font-bold">
                ${results.totalInvestment.toLocaleString()}
              </p>
            </div>

            <div>
              <p className="text-sm text-gray-400">Bitcoin accumulé</p>
              <p className="text-2xl font-bold">
                {results.btcAccumulated.toFixed(8)} BTC
              </p>
            </div>

            <div>
              <p className="text-sm text-gray-400">Valeur estimée</p>
              <p className="text-2xl font-bold text-green-400">
                ${results.estimatedValue.toLocaleString()}
              </p>
            </div>

            <div>
              <p className="text-sm text-gray-400">Profit potentiel</p>
              <p className="text-2xl font-bold text-green-400">
                +{results.profitPercentage.toFixed(2)}%
              </p>
              <p className="text-sm text-gray-400">
                (${results.profit.toLocaleString()})
              </p>
            </div>
          </div>
        </div>

        {/* Graphique */}
        <div className="bg-white/5 rounded-xl p-6">
          <h3 className="text-xl font-semibold mb-6">Évolution estimée</h3>
          {chartData && (
            <Line 
              data={chartData} 
              options={chartOptions}
              className="min-h-[400px]"
            />
          )}
          <p className="text-sm text-gray-400 mt-4 text-center">
            Simulation basée sur une croissance annuelle estimée de {GROWTH_RATE * 100}%
          </p>
        </div>
      </div>
    </div>
  );
}