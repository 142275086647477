import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TrendingUp, ChevronDown, LineChart as LineChartIcon, Loader2, AlertCircle } from 'lucide-react';
import { getNewListings, getMarketOverview, type NewListing } from '../services/bybit';

export default function TopGainersPage() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const [newListings, setNewListings] = useState<NewListing[]>([]);
  const [marketOverview, setMarketOverview] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [listings, overview] = await Promise.all([
          getNewListings(),
          getMarketOverview()
        ]);
        setNewListings(listings);
        setMarketOverview(overview);
        setError(null);
      } catch (err) {
        setError('Erreur lors de la récupération des données');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // Refresh data every 30 seconds
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-md">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center gap-4">
              <LineChartIcon className="w-8 h-8 text-[#fede58]" />
              <span className="text-xl font-bold">Portfolio d'Investissement</span>

              {/* Dropdown Menu */}
              <div className="relative ml-8">
                <button
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
                >
                  Menu
                  <ChevronDown className={`w-4 h-4 transition-transform ${showDropdown ? 'rotate-180' : ''}`} />
                </button>

                {showDropdown && (
                  <div className="absolute top-full left-0 mt-2 w-48 bg-black/90 backdrop-blur-sm rounded-lg shadow-xl border border-white/10 py-2">
                    <Link
                      to="/infopreneur/actualites"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/actualites'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Actualités
                    </Link>
                    <Link
                      to="/infopreneur/blog"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/blog'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Blog
                    </Link>
                    <Link
                      to="/infopreneur/saas/tracker-btc"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/saas/tracker-btc'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Bitcoin Tracker
                    </Link>
                    <Link
                      to="/infopreneur/saas/learn-code"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/saas/learn-code'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Apprend a Code
                    </Link>
                  </div>
                )}
              </div>

              {/* Top Gainers Link */}
              <Link
                to="/infopreneur/saas/portfolio/top-gainers"
                className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
              >
                <TrendingUp className="w-5 h-5" />
                Top Gainers
              </Link>
            </div>

            <Link 
              to="/infopreneur"
              className="flex items-center gap-2 px-6 py-2 bg-[#fede58] text-black rounded-full font-medium hover:bg-[#fede58]/90 transition-colors"
            >
              Home
            </Link>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto p-6 pt-24">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold mb-4">Nouveaux Listings</h1>
          <p className="text-gray-400">Découvrez les nouvelles cryptomonnaies sur Bybit</p>
        </div>

        {/* Market Overview */}
        {marketOverview && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
              <h3 className="text-sm text-gray-400 mb-2">Volume Total 24h</h3>
              <p className="text-2xl font-bold">${(marketOverview.totalVolume / 1e9).toFixed(2)}B</p>
            </div>
            <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
              <h3 className="text-sm text-gray-400 mb-2">Gainers</h3>
              <p className="text-2xl font-bold">{marketOverview.gainers}</p>
            </div>
            <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
              <h3 className="text-sm text-gray-400 mb-2">Paires Totales</h3>
              <p className="text-2xl font-bold">{marketOverview.totalPairs}</p>
            </div>
          </div>
        )}

        {/* New Listings Table */}
        <div className="bg-white/5 rounded-xl backdrop-blur-sm overflow-hidden">
          {loading ? (
            <div className="flex items-center justify-center p-12">
              <Loader2 className="w-8 h-8 animate-spin text-[#fede58]" />
            </div>
          ) : error ? (
            <div className="flex items-center justify-center p-12 text-red-400 gap-2">
              <AlertCircle className="w-6 h-6" />
              {error}
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-white/10">
                    <th className="px-6 py-4 text-left text-sm font-semibold text-gray-400">Token</th>
                    <th className="px-6 py-4 text-right text-sm font-semibold text-gray-400">Prix</th>
                    <th className="px-6 py-4 text-right text-sm font-semibold text-gray-400">24h %</th>
                    <th className="px-6 py-4 text-right text-sm font-semibold text-gray-400">Volume (24h)</th>
                    <th className="px-6 py-4 text-right text-sm font-semibold text-gray-400">Date de listing</th>
                  </tr>
                </thead>
                <tbody>
                  {newListings.map((listing) => (
                    <tr key={listing.symbol} className="border-b border-white/5 hover:bg-white/5 transition-colors">
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <div className="font-semibold">{listing.name}</div>
                          <div className="text-sm text-gray-400">{listing.symbol}</div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right">
                        ${listing.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 8 })}
                      </td>
                      <td className="px-6 py-4 text-right">
                        <span className={listing.change24h >= 0 ? 'text-green-400' : 'text-red-400'}>
                          {listing.change24h >= 0 ? '+' : ''}{listing.change24h.toFixed(2)}%
                        </span>
                      </td>
                      <td className="px-6 py-4 text-right">
                        ${(listing.volume24h / 1000000).toFixed(2)}M
                      </td>
                      <td className="px-6 py-4 text-right text-gray-400">
                        {new Date(listing.launchDate).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Market Insights */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
            <h2 className="text-xl font-semibold mb-4">Analyse du Marché</h2>
            <p className="text-gray-300 leading-relaxed">
              Les nouveaux listings sur Bybit représentent des opportunités d'investissement précoce.
              Surveillez attentivement les volumes d'échange et la volatilité des prix pour identifier
              les projets prometteurs.
            </p>
          </div>

          <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
            <h2 className="text-xl font-semibold mb-4">Recommandations</h2>
            <ul className="space-y-3 text-gray-300">
              <li className="flex items-start gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-green-400 mt-2"></div>
                <span>Faites des recherches approfondies sur les nouveaux projets</span>
              </li>
              <li className="flex items-start gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-green-400 mt-2"></div>
                <span>Commencez avec de petites positions pour les nouveaux tokens</span>
              </li>
              <li className="flex items-start gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-green-400 mt-2"></div>
                <span>Surveillez les annonces officielles de Bybit</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}