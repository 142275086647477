import React from 'react';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';
import FAQ from '../components/FAQ';

export default function FaqPage() {
  return (
    <div className="min-h-screen bg-[#111827]">
      {/* Header with Home CTA */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-md">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <h1 className="text-2xl font-bold text-white">FAQ</h1>
            <Link 
              to="/infopreneur"
              className="flex items-center gap-2 px-6 py-2 bg-[#fede58] text-black rounded-full font-medium hover:bg-[#fede58]/90 transition-colors group"
            >
              <Home className="w-5 h-5 transition-transform group-hover:rotate-12" />
              Home
            </Link>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="pt-24">
        <FAQ />
      </div>
    </div>
  );
}