import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import { DollarSign, TrendingUp, BarChart, ChevronDown, LineChart as LineChartIcon } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const PortfolioPage = () => {
  const [investment, setInvestment] = useState(1000);
  const [isEditing, setIsEditing] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  
  const [performanceData, setPerformanceData] = useState<any[]>([]);
  const [allocationData, setAllocationData] = useState<any[]>([]);
  const [currentValue, setCurrentValue] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [riskLevel, setRiskLevel] = useState('');

  useEffect(() => {
    const generatePerformanceData = () => {
      let currentVal = investment;
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
      return months.map(month => {
        const monthlyReturn = (Math.random() * 0.15) - 0.05;
        currentVal = currentVal * (1 + monthlyReturn);
        return {
          month,
          value: Math.round(currentVal)
        };
      });
    };

    const generateAllocationData = () => {
      return [
        { name: 'Actions', value: Math.round(investment * 0.5) },
        { name: 'Obligations', value: Math.round(investment * 0.3) },
        { name: 'Crypto', value: Math.round(investment * 0.1) },
        { name: 'Cash', value: Math.round(investment * 0.1) }
      ];
    };

    const calculateRiskLevel = () => {
      if (investment < 1000) return 'Faible';
      if (investment < 5000) return 'Modéré';
      return 'Élevé';
    };

    const newPerformanceData = generatePerformanceData();
    const newAllocationData = generateAllocationData();
    
    setPerformanceData(newPerformanceData);
    setAllocationData(newAllocationData);
    setCurrentValue(newPerformanceData[newPerformanceData.length - 1].value);
    setRiskLevel(calculateRiskLevel());
    setTotalReturn(((newPerformanceData[newPerformanceData.length - 1].value - investment) / investment * 100));
  }, [investment]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const handleInvestmentChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const value = parseInt((e.target as HTMLInputElement).value);
      if (!isNaN(value) && value > 0) {
        setInvestment(value);
        setIsEditing(false);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-md">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center gap-4">
              <LineChartIcon className="w-8 h-8 text-[#fede58]" />
              <span className="text-xl font-bold">Portfolio d'Investissement</span>

              {/* Dropdown Menu */}
              <div className="relative ml-8">
                <button
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
                >
                  Menu
                  <ChevronDown className={`w-4 h-4 transition-transform ${showDropdown ? 'rotate-180' : ''}`} />
                </button>

                {showDropdown && (
                  <div className="absolute top-full left-0 mt-2 w-48 bg-black/90 backdrop-blur-sm rounded-lg shadow-xl border border-white/10 py-2">
                    <Link
                      to="/infopreneur/actualites"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/actualites'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Actualités
                    </Link>
                    <Link
                      to="/infopreneur/blog"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/blog'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Blog
                    </Link>
                    <Link
                      to="/infopreneur/saas/tracker-btc"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/saas/tracker-btc'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Bitcoin Tracker
                    </Link>
                    <Link
                      to="/infopreneur/saas/learn-code"
                      className={`block px-4 py-2 text-sm ${
                        location.pathname === '/infopreneur/saas/learn-code'
                          ? 'bg-white/10 text-white'
                          : 'text-gray-300 hover:bg-white/5 hover:text-white'
                      } transition-colors`}
                      onClick={() => setShowDropdown(false)}
                    >
                      Apprend a Code
                    </Link>
                  </div>
                )}
              </div>

              {/* Top Gainers Link */}
              <Link
                to="/infopreneur/saas/portfolio/top-gainers"
                className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
              >
                <TrendingUp className="w-5 h-5" />
                Top Gainers
              </Link>
            </div>

            <Link 
              to="/infopreneur"
              className="flex items-center gap-2 px-6 py-2 bg-[#fede58] text-black rounded-full font-medium hover:bg-[#fede58]/90 transition-colors"
            >
              Home
            </Link>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto p-6 pt-24">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold mb-4">Mon Portfolio d'Investissement</h1>
          <div className="relative inline-flex items-center">
            <p className="text-xl">
              Investissement initial: 
              {isEditing ? (
                <input
                  type="number"
                  defaultValue={investment}
                  onKeyDown={handleInvestmentChange}
                  onBlur={() => setIsEditing(false)}
                  className="w-32 ml-2 px-2 py-1 bg-white/10 border border-white/20 rounded text-white"
                  autoFocus
                />
              ) : (
                <span 
                  className="font-bold ml-2 cursor-pointer hover:text-[#fede58]"
                  onClick={() => setIsEditing(true)}
                >
                  {investment.toLocaleString()}$
                </span>
              )}
            </p>
            
            <div className="relative ml-4 inline-flex items-center bg-purple-300/20 backdrop-blur-sm text-purple-200 px-4 py-2 rounded-lg text-sm">
              <div className="absolute left-0 top-1/2 transform -translate-x-2 -translate-y-1/2 w-0 h-0 border-t-8 border-b-8 border-r-8 border-t-transparent border-b-transparent border-r-purple-300/20"></div>
              Cliquez pour modifier la valeur
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="bg-blue-500/10 p-6 rounded-xl backdrop-blur-sm">
            <div className="flex items-center justify-between">
              <DollarSign className="text-blue-400" size={24} />
              <div className="text-right">
                <p className="text-sm text-gray-400">Valeur Actuelle</p>
                <p className="text-xl font-bold text-blue-400">{currentValue.toLocaleString()}$</p>
              </div>
            </div>
          </div>

          <div className="bg-green-500/10 p-6 rounded-xl backdrop-blur-sm">
            <div className="flex items-center justify-between">
              <TrendingUp className="text-green-400" size={24} />
              <div className="text-right">
                <p className="text-sm text-gray-400">Rendement Total</p>
                <p className="text-xl font-bold text-green-400">{totalReturn.toFixed(2)}%</p>
              </div>
            </div>
          </div>

          <div className="bg-yellow-500/10 p-6 rounded-xl backdrop-blur-sm">
            <div className="flex items-center justify-between">
              <BarChart className="text-yellow-400" size={24} />
              <div className="text-right">
                <p className="text-sm text-gray-400">Niveau de Risque</p>
                <p className="text-xl font-bold text-yellow-400">{riskLevel}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <div className="bg-white/5 p-6 rounded-xl backdrop-blur-sm">
            <h2 className="text-xl font-semibold mb-6">Performance du Portfolio</h2>
            <LineChart width={400} height={300} data={performanceData}>
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
              <XAxis dataKey="month" stroke="rgba(255,255,255,0.5)" />
              <YAxis stroke="rgba(255,255,255,0.5)" />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: 'rgba(0,0,0,0.8)', 
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '8px'
                }}
              />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="value" 
                stroke="#0088FE" 
                name="Valeur ($)"
                strokeWidth={2}
              />
            </LineChart>
          </div>

          <div className="bg-white/5 p-6 rounded-xl backdrop-blur-sm">
            <h2 className="text-xl font-semibold mb-6">Répartition des Actifs</h2>
            <PieChart width={400} height={300}>
              <Pie
                data={allocationData}
                cx={200}
                cy={150}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label
              >
                {allocationData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: 'rgba(0,0,0,0.8)', 
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '8px'
                }}
              />
              <Legend />
            </PieChart>
          </div>
        </div>

        <div className="bg-white/5 p-6 rounded-xl backdrop-blur-sm">
          <h2 className="text-xl font-semibold mb-6">Recommandations d'Investissement</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="font-medium text-lg mb-4">Diversification Suggérée</h3>
              <ul className="space-y-2 text-gray-300">
                <li>• 50% Actions - Pour la croissance à long terme</li>
                <li>• 30% Obligations - Pour la stabilité</li>
                <li>• 10% Crypto - Pour le potentiel de haute croissance</li>
                <li>• 10% Cash - Pour les opportunités</li>
              </ul>
            </div>
            <div>
              <h3 className="font-medium text-lg mb-4">Conseils</h3>
              <ul className="space-y-2 text-gray-300">
                <li>• Investir régulièrement pour profiter de la moyenne des coûts</li>
                <li>• Rééquilibrer le portfolio tous les trimestres</li>
                <li>• Garder une vision à long terme</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioPage;