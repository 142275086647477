import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { 
  Home,
  Dashboard,
  InfopreneurPage,
  BlogPage,
  EditionPage,
  TemoignagePage,
  FaqPage,
  LearnCodePage 
} from '@/pages';
import BitcoinTrackerPage from '@/pages/BitcoinTrackerPage';
import PortfolioPage from '@/pages/PortfolioPage';
import LearnCodeBlogPage from '@/pages/learn-code/BlogPage';
import { PrivateRoute } from '@/components/common';

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/infopreneur/*" element={<InfopreneurPage />} />
    <Route path="/infopreneur/blog" element={<BlogPage />} />
    <Route path="/infopreneur/edition" element={<EditionPage />} />
    <Route path="/infopreneur/temoignage" element={<TemoignagePage />} />
    <Route path="/infopreneur/faq" element={<FaqPage />} />
    <Route path="/infopreneur/saas/learn-code" element={<LearnCodePage />} />
    <Route path="/infopreneur/saas/tracker-btc" element={<BitcoinTrackerPage />} />
    <Route path="/infopreneur/saas/portfolio" element={<PortfolioPage />} />
    <Route path="/infopreneur/saas/learn-code/blog" element={<LearnCodeBlogPage />} />
    <Route path="/dashboard" element={
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    } />
  </Routes>
);