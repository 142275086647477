import React from 'react';
import { CodeHeader } from '../../components/learn-code/CodeHeader';
import { BlogPosts } from '../../components/learn-code/BlogPosts';
import { CodeFooter } from '../../components/learn-code/CodeFooter';

export default function BlogPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white pt-16">
      <CodeHeader />
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-12">Blog</h1>
        <BlogPosts />
      </main>
      <CodeFooter />
    </div>
  );
}