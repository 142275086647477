import React, { useState } from 'react';
import { Code, BookOpen, Users, Trophy } from 'lucide-react';
import { PracticalLearningModal } from './PracticalLearningModal';
import { StructuredCoursesModal } from './StructuredCoursesModal';
import { ActiveCommunityModal } from './ActiveCommunityModal';
import { CertificationsModal } from './CertificationsModal';

const features = [
  {
    icon: <Code className="w-6 h-6" />,
    title: "Apprentissage Pratique",
    description: "Codez directement dans votre navigateur avec notre éditeur interactif"
  },
  {
    icon: <BookOpen className="w-6 h-6" />,
    title: "Cours Structurés",
    description: "Parcours d'apprentissage progressif du débutant à l'expert"
  },
  {
    icon: <Users className="w-6 h-6" />,
    title: "Communauté Active",
    description: "Échangez avec d'autres apprenants et des mentors expérimentés"
  },
  {
    icon: <Trophy className="w-6 h-6" />,
    title: "Certifications",
    description: "Obtenez des certificats reconnus pour valoriser vos compétences"
  }
];

export function Features() {
  const [showPracticalLearning, setShowPracticalLearning] = useState(false);
  const [showStructuredCourses, setShowStructuredCourses] = useState(false);
  const [showActiveCommunity, setShowActiveCommunity] = useState(false);
  const [showCertifications, setShowCertifications] = useState(false);

  return (
    <section className="py-20 bg-black/50">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="bg-white/5 p-6 rounded-xl hover:bg-white/10 transition-colors cursor-pointer"
              onClick={() => {
                if (index === 0) setShowPracticalLearning(true);
                if (index === 1) setShowStructuredCourses(true);
                if (index === 2) setShowActiveCommunity(true);
                if (index === 3) setShowCertifications(true);
              }}
            >
              <div className="w-12 h-12 bg-blue-600/20 rounded-lg flex items-center justify-center mb-4">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      <PracticalLearningModal 
        isOpen={showPracticalLearning}
        onClose={() => setShowPracticalLearning(false)}
      />
      
      <StructuredCoursesModal
        isOpen={showStructuredCourses}
        onClose={() => setShowStructuredCourses(false)}
      />

      <ActiveCommunityModal
        isOpen={showActiveCommunity}
        onClose={() => setShowActiveCommunity(false)}
      />

      <CertificationsModal
        isOpen={showCertifications}
        onClose={() => setShowCertifications(false)}
      />
    </section>
  );
}