import React from 'react';
import { X, Clock, TrendingUp, Users, Shield } from 'lucide-react';

interface HalvingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function HalvingModal({ isOpen, onClose }: HalvingModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-start justify-center pt-20"
      onClick={onClose}
    >
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm"></div>
      
      <div 
        className="relative bg-gray-900/90 backdrop-blur-md rounded-xl shadow-2xl border border-white/10 w-full max-w-4xl mx-4 max-h-[80vh] overflow-y-auto transform transition-all duration-200 animate-[fadeIn_0.2s_ease-out]"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 bg-gray-900/90 backdrop-blur-md p-6 border-b border-white/10 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-[#F7931A]">Le Halving Bitcoin</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-8">
          <div className="text-gray-300 leading-relaxed">
            <p>
              L'halving (ou "réduction de moitié" en français) est un événement programmé dans le protocole de certaines cryptomonnaies, 
              comme le Bitcoin, qui réduit de moitié la récompense accordée aux mineurs pour la validation des blocs de transactions. 
              Ce mécanisme est conçu pour contrôler l'émission de nouvelles unités de la cryptomonnaie et limiter son inflation.
            </p>
          </div>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Clock className="w-6 h-6 text-[#F7931A]" />
              Comment fonctionne l'halving ?
            </h3>
            <div className="space-y-4 text-gray-300">
              <div>
                <h4 className="font-medium text-white mb-2">Récompense des mineurs</h4>
                <p>
                  Les mineurs valident les transactions sur la blockchain en résolvant des problèmes mathématiques complexes. 
                  En échange, ils reçoivent une récompense en cryptomonnaie (par exemple, en Bitcoin).
                </p>
              </div>

              <div>
                <h4 className="font-medium text-white mb-2">Réduction de la récompense</h4>
                <p>
                  L'halving réduit cette récompense de moitié à intervalles réguliers. Pour Bitcoin, cela se produit tous les 
                  210 000 blocs minés, soit environ tous les 4 ans.
                </p>
              </div>

              <div className="bg-white/5 rounded-xl p-4">
                <h4 className="font-medium text-white mb-2">Exemple pour Bitcoin</h4>
                <ul className="space-y-2">
                  <li>2009 : Récompense initiale de 50 BTC par bloc</li>
                  <li>2012 : Premier halving → récompense réduite à 25 BTC</li>
                  <li>2016 : Deuxième halving → récompense réduite à 12,5 BTC</li>
                  <li>2020 : Troisième halving → récompense réduite à 6,25 BTC</li>
                  <li>2024 : Quatrième halving prévu → récompense réduite à 3,125 BTC</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Shield className="w-6 h-6 text-[#F7931A]" />
              Objectifs de l'halving
            </h3>
            <div className="space-y-4 text-gray-300">
              <div>
                <h4 className="font-medium text-white mb-2">Contrôle de l'inflation</h4>
                <p>
                  En réduisant progressivement la récompense des mineurs, l'halving limite la création de nouvelles unités 
                  de la cryptomonnaie. Cela permet de contrôler l'inflation et de maintenir la rareté de l'actif.
                </p>
              </div>

              <div>
                <h4 className="font-medium text-white mb-2">Rareté et valeur</h4>
                <p>
                  La réduction de l'offre peut augmenter la rareté de la cryptomonnaie, ce qui, selon la loi de l'offre 
                  et de la demande, peut entraîner une augmentation de sa valeur.
                </p>
              </div>

              <div>
                <h4 className="font-medium text-white mb-2">Émission totale limitée</h4>
                <p>
                  Pour Bitcoin, l'halving garantit que le nombre total de bitcoins ne dépassera jamais 21 millions, 
                  ce qui en fait un actif déflationniste.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <TrendingUp className="w-6 h-6 text-[#F7931A]" />
              Impact de l'halving
            </h3>
            <div className="space-y-4 text-gray-300">
              <div>
                <h4 className="font-medium text-white mb-2">Sur les mineurs</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Réduction des revenus : Les mineurs voient leurs revenus diminuer</li>
                  <li>Augmentation des coûts : Optimisation nécessaire des opérations</li>
                </ul>
              </div>

              <div>
                <h4 className="font-medium text-white mb-2">Sur le marché</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Volatilité accrue pendant la période</li>
                  <li>Attention médiatique importante</li>
                  <li>Historique de hausse significative à long terme</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
              <Users className="w-6 h-6 text-[#F7931A]" />
              Halving dans d'autres cryptomonnaies
            </h3>
            <div className="bg-white/5 rounded-xl p-4 text-gray-300">
              <p className="mb-4">L'halving n'est pas exclusif au Bitcoin. D'autres cryptomonnaies utilisent un mécanisme similaire :</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Litecoin (LTC) : Halving tous les 840 000 blocs</li>
                <li>Bitcoin Cash (BCH) : Halving tous les 210 000 blocs</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}