import React from 'react';
import InfoHeader from '../components/InfoHeader';
import InfoHero from '../components/InfoHero';
import FloatingCTA from '../components/FloatingCTA';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';

export default function InfopreneurPage() {
  return (
    <div className="min-h-screen bg-[#111827] text-white">
      <InfoHeader />
      <InfoHero />
      <FloatingCTA />
      <Pricing />
      <Footer />
    </div>
  );
}