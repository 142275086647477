import React from 'react';
import { X } from 'lucide-react';

interface ActiveCommunityModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ActiveCommunityModal({ isOpen, onClose }: ActiveCommunityModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-gray-900/95 backdrop-blur-md w-full max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 text-white"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Communauté Active d'Apprenants</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="space-y-8">
          <p className="text-gray-300">
            S'intégrer dans une communauté active peut grandement accélérer votre apprentissage, 
            vous permettre de poser des questions et d'échanger des idées avec d'autres apprenants 
            et des mentors expérimentés.
          </p>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">1. Plateformes Générales</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">Stack Overflow</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Une des communautés les plus actives pour poser des questions techniques.</li>
                  <li>Recherchez des réponses aux problèmes spécifiques de programmation.</li>
                  <li>Participez en posant ou en répondant à des questions.</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">GitHub</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Contribuez à des projets open-source.</li>
                  <li>Suivez d'autres développeurs pour apprendre de leurs travaux.</li>
                  <li>Explorez des discussions sur des projets spécifiques dans les "Issues".</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">Reddit</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>r/learnprogramming</li>
                  <li>r/programming</li>
                  <li>r/webdev</li>
                  <li>r/linux pour les utilisateurs de Linux et Unix</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">2. Communautés pour Développement Web</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">freeCodeCamp Forums</h4>
                <p className="text-gray-300">Une large communauté pour poser des questions, partager des projets et obtenir de l'aide.</p>
              </div>

              <div>
                <h4 className="font-semibold text-white">DEV Community</h4>
                <p className="text-gray-300">Blog collaboratif où les développeurs partagent leurs expériences.</p>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">3. Plateformes de Mentorat</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">MentorCruise</h4>
                <p className="text-gray-300">Trouvez un mentor expérimenté pour vous guider dans votre carrière.</p>
              </div>

              <div>
                <h4 className="font-semibold text-white">Exercism</h4>
                <p className="text-gray-300">Apprenez à travers des exercices guidés avec un mentor.</p>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">4. Hackathons et Meetups</h3>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Devpost : Participez à des hackathons en ligne.</li>
              <li>Meetup : Trouvez des événements locaux pour rencontrer d'autres développeurs.</li>
              <li>Open Collective : Contribuez et interagissez avec des projets open-source.</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4 text-blue-400">Conseils pour participer activement</h3>
            <div className="space-y-4 pl-4">
              <div>
                <h4 className="font-semibold text-white">Posez des questions claires</h4>
                <p className="text-gray-300">Expliquez le problème, fournissez du contexte et montrez ce que vous avez essayé.</p>
              </div>

              <div>
                <h4 className="font-semibold text-white">Contribuez à votre tour</h4>
                <ul className="list-disc pl-6 text-gray-300 space-y-1">
                  <li>Aidez les autres dans des domaines où vous êtes à l'aise.</li>
                  <li>Partagez vos projets ou solutions.</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-white">Participez régulièrement</h4>
                <p className="text-gray-300">Engagez-vous dans des discussions et événements pour renforcer vos connexions.</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}