import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import axios from 'axios';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const timeframes = [
  { label: '1D', days: 1 },
  { label: '1W', days: 7 },
  { label: '1M', days: 30 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 'max' }
];

export default function PriceEvolution() {
  const [priceData, setPriceData] = useState<{ prices: number[][]; }>();
  const [selectedTimeframe, setSelectedTimeframe] = useState(timeframes[2]); // 1M par défaut
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPriceHistory = async () => {
      try {
        setLoading(true);
        const days = selectedTimeframe.days === 'max' ? 'max' : selectedTimeframe.days;
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=${days}`
        );
        setPriceData(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'historique des prix:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPriceHistory();
  }, [selectedTimeframe]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index' as const,
        intersect: false,
        callbacks: {
          label: (context: any) => `$${context.parsed.y.toLocaleString()}`
        }
      },
    },
    scales: {
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.8)',
          callback: (value: number) => `$${value.toLocaleString()}`
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.8)',
          maxTicksLimit: 8
        }
      }
    }
  };

  const formatData = () => {
    if (!priceData?.prices) return null;

    const labels = priceData.prices.map(price => 
      new Date(price[0]).toLocaleDateString('fr-FR', {
        month: 'short',
        day: 'numeric',
        ...(selectedTimeframe.days > 30 && { year: 'numeric' })
      })
    );

    return {
      labels,
      datasets: [
        {
          data: priceData.prices.map(price => price[1]),
          borderColor: '#F7931A',
          backgroundColor: 'rgba(247, 147, 26, 0.5)',
          tension: 0.4,
          fill: true,
        }
      ]
    };
  };

  if (loading) {
    return (
      <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm animate-pulse">
        <div className="flex gap-4 mb-6">
          {timeframes.map((_, index) => (
            <div key={index} className="w-16 h-8 bg-white/10 rounded-lg"></div>
          ))}
        </div>
        <div className="h-[400px] bg-white/10 rounded-lg"></div>
      </div>
    );
  }

  const chartData = formatData();
  if (!chartData) return null;

  return (
    <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
      <div className="flex gap-4 mb-6">
        {timeframes.map((timeframe) => (
          <button
            key={timeframe.label}
            onClick={() => setSelectedTimeframe(timeframe)}
            className={`px-4 py-2 rounded-lg transition-colors ${
              selectedTimeframe === timeframe
                ? 'bg-[#F7931A] text-white'
                : 'bg-white/10 hover:bg-white/20'
            }`}
          >
            {timeframe.label}
          </button>
        ))}
      </div>
      <Line options={options} data={chartData} />
    </div>
  );
}